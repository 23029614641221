import { useLang } from "Lang/useLang"
import { BtnPdf, Rows, Text } from "UIKit";
import { downloadPdf } from "helpers/fileDownload";
import { useState } from "react";

export const DownloadPDF = ({ id, info }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [per, setPer] = useState(0);
    const { $ } = useLang();

    const handleDownload = async () => {
        try {
            setIsLoading(true);
            const name = `${info.infantFirstName}_${info.lastName}_Risk_Report`;

            await downloadPdf(id, name, setPer);
            setIsLoading(false);
        } catch (error) {
            window.toastError(error.message);
            setIsLoading(false);
        }
    }

    return (
    <Rows>
        <BtnPdf isLoading={isLoading} onClick={handleDownload}>{$('PLATFORM_REPORT_BTN_PDF')}</BtnPdf>
        {isLoading && <Text>{$('PLATFORM_REPORT_DOWNLOADING', per)}</Text>}
    </Rows>
    )
}