import { getForgotDetails, setForgotCode } from "Context/forgotPassword";
import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { useLang } from "Lang/useLang";
import {
  BtnLink,
  BtnNext,
  Center,
  Input,
  Label1,
  Label2,
  Middle,
  RoundBox,
  RowsMedium,
  RowsShort,
} from "UIKit";
import { LoginWrapper } from "Wrapper/LoginWrapper";
import { api, routes } from "helpers/api";
import { useNavigate, Navigate } from "react-router-dom";

export const EmailCallback = () => {
  const { $ } = useLang();
  const navigate = useNavigate();
  const details = getForgotDetails();
  const Code = useInput("", $('EMAIL_CB_CODE_PH'), "empty");

  const validate = useValidator(Code);

  const handleNext = () => {
    if (!validate()) {
      return;
    }

    setForgotCode(Code.value);

    api
      .post(routes.resultsAdmin + "/code-email-validate", {
        email: details.email,
        code: Code.value
      })
      .then(() => {
        navigate(`/login/new-password`);
      });
  };

  const handleResend = async () => {
    await api
      .post(routes.resultsAdmin + "/forget-password", {
        email: details.email,
      })
      .then(() => {
        window.toast($('EMAIL_CB_TOAST_SENT'));
      });
  };

  const isDisabled = !Code.value;
  if (!details.email) {
    return <Navigate to="/login" />;
  }

  return (
    <LoginWrapper
      title={$('EMAIL_CB_TITLE')}
      info={$('EMAIL_CB_INFO', <Label1 bold>{details.email}</Label1>)}
    >
      <RoundBox decor={false}>
        <Middle>
          <RowsMedium>
            <RowsShort>
              <Input focus title="Code" {...Code} />
            </RowsShort>

            <BtnNext disabled={isDisabled} onClick={handleNext}>
              {$('EMAIL_CB_BTN_CONTINUE')}
            </BtnNext>

            <Label2>
              {$('EMAIL_CB_EMAIL_INFO', <br />, <br />, <Label2 bold>{$('PREFIX_NO_REPLY_EMAIL')}</Label2>)}
            </Label2>

            <Center>
              <BtnLink onClick={handleResend}>{$('BTN_RESEND_CODE')}</BtnLink>
            </Center>
          </RowsMedium>
        </Middle>
      </RoundBox>
    </LoginWrapper>
  );
};
