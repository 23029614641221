const STORAGE = 'user_forgot_password';

export const getForgotDetails = () => {
    const details = localStorage.getItem(STORAGE) || '{}';
    return JSON.parse(details);
};
export const setForgotEmail = (email) => {
    const details = getForgotDetails();
    setForgotDetails({ ...details, email });
};

export const setForgotCode = (code) => {
    const details = getForgotDetails();
    setForgotDetails({ ...details, code });
};

export const setForgotDetails = (details) => {
    localStorage.setItem(STORAGE, JSON.stringify(details))
};
export const deleteForgotDetails = () => localStorage.removeItem(STORAGE);