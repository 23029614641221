import { useEffect, useRef } from 'react';
import './Overflow.css';

export const Overflow = ({ children }) => {
    const wrapRef = useRef();
    const topShadowRef = useRef();
    const bottomShadowRef = useRef();

    useEffect(() => {
        wrapRef?.current?.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            wrapRef?.current?.removeEventListener('scroll', handleScroll);
        }
    }, [children])

    const handleScroll = () => {
        if(!wrapRef.current) { return }
        if(!topShadowRef.current) { return }
        if(!bottomShadowRef.current) { return }

        const endScroll = wrapRef.current.scrollHeight -  wrapRef.current.clientHeight - 20;

        topShadowRef.current.style.display = wrapRef.current.scrollTop < 10 ? 'none' : 'block';
        bottomShadowRef.current.style.display = wrapRef.current.scrollTop > endScroll ? 'none' : 'block';

    }

    return (
        <div className="Overflow">
            <div className="shade" ref={topShadowRef}></div>
            <div className="shade" ref={bottomShadowRef}></div>
            <div className='content' ref={wrapRef}>
                {children}
            </div>
        </div>
    )
}