import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { deleteUserToken, getUserToken, setUserToken } from "./storage";
import { api, routes } from "helpers/api";

export const userContext = createContext({});

const Provider = userContext.Provider;

export const useUser = () => {
    const [data, setData] = useState(null);
    const isUser = getUserToken() ? true : false;
    const [isLoading, setIsLoading] = useState(true);
    const isSuperDietitian = data?.dietitian === 'super';

    useEffect(() => {
        if (isUser && !data) {
            setUserData();
        } else if (!isUser && data) {
            setData(null);
        } else if(!isUser && !data) {
            setIsLoading(false);
        }
    }, [isUser])

    const setUserData = async () => {
        try {
            setIsLoading(true);
            const resp = await api.get(routes.resultsAdmin + '/me', undefined, undefined, true)
            setData(resp);
            setIsLoading(false);
        } catch (error) {
           window.location.href = '/login';
        }
    }

    const value = {
        data,
        isUser,
        user: getUserToken(),
        isSuperDietitian,
        isLoading,
        login: async () => {
            setUserToken('ON');

            window.location.href = '/';
        },
        logout: async () => {
            deleteUserToken();
            await api.post(routes.resultsAdmin + '/logout', undefined, undefined, true)
            window.location.href = '/login';
        }
    }

    return value;
}

export const UserProvider = ({ children }) => {
    const value = useUser();

    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}

export const AuthRoute = ({ elem: Elem, children }) => {
    const { user, isLoading } = useContext(userContext);

    if(isLoading) {
        return <></>
    }

    if (!user) {
        return <Navigate to='/login' />
    }
    return children || <Elem />
}

export const AuthSuperDietitian = ({ elem: Elem, children }) => {
    const { user, isLoading, isSuperDietitian } = useContext(userContext);

    if(isLoading) {
        return <></>
    }

    if (!user) {
        return <Navigate to='/login' />
    }

    if(!isSuperDietitian) {
        return <Navigate to='/platform' />
    }
    return children || <Elem />
}