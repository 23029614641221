import { useState } from "react"

export const useInput = (initialState, placeholder, validation) => {
    const [value, setValue] = useState(initialState || '');

    const empty = () => setValue('');

    return {
        value,
        onChange: setValue,
        p: placeholder,
        validation: { 
            type: 'input',
            error: '',
            prefix: validation
        },
        empty
    }
}