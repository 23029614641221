import { createContext, useEffect, useState } from "react";

export const modeContext = createContext({});

const Provider = modeContext.Provider;
const STORAGE = 'storage-mode';

export const ModeProvider = ({ children }) => {
    const [mode, setMode] = useState(localStorage.getItem(STORAGE) || 'light');

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleChangeMode);

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleChangeMode);
        }
    }, [])

    const handleChangeMode = event => {
        changeMode(event.matches ? "dark" : "light");
    };

    const changeMode = (m) => {
        setMode(m);
        localStorage.setItem(STORAGE, m)
    }

    const toDarkMode = () => changeMode('dark')
    const toLightMode = () => changeMode('light')

    const value = {
        mode,
        toDarkMode,
        toLightMode
    }

    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}