import { userContext } from "Context/userContext";
import { Sizer } from "UIKit"
import { useContext, useState } from "react";
import { EnterCode } from "./EnterCode";
import { Regenerate } from "./Regenerate";
import { Sent } from "./Sent";


export const PatientCode = ({ isBack }) => {
    const [view, setView] = useState("EnterCode")
    const { data } = useContext(userContext);

    if (!data) { return <></> }

    const views = [
        {
            id: "EnterCode",
            elem: <EnterCode
                isBack={isBack}
                onRegenerate={() => setView("Regenerate")}
            />,
            size: 'regular'
        },
        {
            id: "Regenerate",
            elem: <Regenerate
                onBack={() => setView("EnterCode")}
                onSent={() => setView("Sent")}
            />,
            size: 'wide'
        },
        {
            id: "Sent",
            elem: <Sent
                onBack={() => setView("EnterCode")}
            />,
            size: 'wide'
        }
    ]

    const selected = views.find(i => i.id === view);

    return (
        <Sizer size={selected.size}>
            {selected.elem}
        </Sizer>
    )
}