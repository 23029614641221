import { Body2, H4 } from 'UIKit';
import './Sep.css';

export const Sep = ({ children }) => {
    return (
        <div className='Sep'>
            <div className='grad'></div>
            <H4>{children}</H4>
            <div className='grad'></div>
        </div>
    )
}


export const SepLine = () => {
    return (
        <div className='SepLine'>
            
        </div>
    )
}