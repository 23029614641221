import { Modal } from "Shared/Modal/Modal";
import { Body2, CenterAlign, Content, Grid, GridEven, H1, IconArrowL, Line, Rows, RowsMedium, SpreadEvenBase, Touchable } from "UIKit";
import { Dot, IconRound } from "UIKit/Elements/Icon/Icon";
import { Main } from "Wrapper/Main";
import { api, routes } from "helpers/api";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "Context/userContext";
import { PatientCode } from "../PatientCode";
import { Score } from "./Score";
import { useLang } from "Lang/useLang";
import { DownloadPDF } from "./DownloadPDF";
import { SendEmail } from "./SendEmail";
import { Idle } from "UIKit/Elements/Idle/Idle";
import { Answers } from "./Answers";
import { OverrideScore } from "./OverrideScore";

const genderList = [
    { id: "female", title: "FIELD_KEY_FEMALE" },
    { id: "male", title: "FIELD_KEY_MALE" },
    { id: "unknown", title: "FIELD_KEY_UNKNOWN" }
]

const riskList = [
    { id: 'select', value: "MD_RISK_SELECT", icon: <Dot /> },
    { id: 'low', value: "MD_RISK_LOW", icon: <Dot color="low" /> },
    { id: 'high', value: "MD_RISK_HIGH", icon: <Dot color="high" /> }
]

export const PlatformView = () => {
    const { $ } = useLang();
    const [data, setData] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const [FAOverride, setFAOverride] = useState('select');
    const [ADOverride, setADOverride] = useState('select');
    const [isLoadingOverride, setIsLoadingOverride] = useState(false);
    const { data: userData, user, logout, isSuperDietitian } = useContext(userContext);
    const isSuperEdit = isSuperDietitian && !data?.override;
    const isEdited = isSuperEdit && (FAOverride != 'select' || ADOverride != 'select')

    useEffect(() => {
        if (isEdited) {
            window.addEventListener('beforeunload', warnReloadIfNeeded);
        }
        return () => {
            window.removeEventListener('beforeunload', warnReloadIfNeeded)
        }
    }, [isEdited])

    function warnReloadIfNeeded(e) {
        const message = $('OVERRIDE_WARN_RELOAD');

        e.returnValue = message

        return message;
    }

    const handleWarning = () => {
        const msg = $('GLOBAL_IDLE');
        window.popup({ msg })
    }
    const handleReset = async () => {
        if (isSuperDietitian && !isEdited) {
            if (isEdited) {
                const isOk = await window.conf($('OVERRIDE_WARN_RELOAD'), $('PLATFORM_CONF_TITLE'));
                if (isOk) {
                    navigate('/dashboard');
                }
            } else {
                navigate('/dashboard');
            }
        } else {
            const isOk = await window.conf($('PLATFORM_CONF_RESET', <b>{data.info.firstName}</b>), $('PLATFORM_CONF_TITLE'));
            if (isOk) {
                window.popup({ elem: <PatientCode /> })
            }
        }
    }

    useEffect(() => {
        if (!params.code) {
            window.popup({ elem: <PatientCode /> })
            setData(null);
            return;
        }
        api.get(routes.ml + '/' + params.code)
            .then(resp => {
                if (resp.not_verified) {
                    handleNotVerified();
                } else {
                    setData(resp);
                    window.popupHide();
                }
            })
    }, [params.code])

    const handleNotVerified = async () => {
        const msg = $('RESULTS_VIEW_NOT_VERIFIED');
        await window.popup({ msg });
        window.popup({ elem: <PatientCode /> })
    }

    const handleSend = (isBooking) => {
        setIsLoadingOverride(true);

        api.post(routes.ml + '/' + data._id + '/override', {
            ADOverride,
            FAOverride,
            isBooking
        })
            .then(resp => {
                window.popupHide();
                navigate('/dashboard');
            })
            .finally(() => {
                setIsLoadingOverride(false);
            })
    }

    const isSendDisabled = ADOverride === 'select' || FAOverride === 'select';
    const score = [ADOverride, FAOverride].includes('high') ? 'high' : 'low';
    const overrideField = ADOverride === 'high' ? 'AD' : 'FA';

    return (
        <Main>
            <Idle isLogin={!!user} onLogout={logout} onWarning={handleWarning} />
            <Modal />
            <RowsMedium>
                <Line>
                    <H1 light>{$('PLATFORM_TITLE')}</H1>
                    {userData && (
                        <H1>{userData.firstName}</H1>
                    )}

                </Line>
                <Touchable onClick={handleReset}>
                    <Line>
                        <IconRound i={IconArrowL} />
                        <Body2>{$(isSuperDietitian ? 'DASHBOARD_BACK' : 'PLATFORM_BACK')}</Body2>
                    </Line>
                </Touchable>
                {data && (
                    <div>
                        <Grid layout="platform">
                            <div>
                                <Answers data={data} />
                            </div>
                            <Rows>
                                <GridEven media="score">
                                    <Score
                                        field="AD"
                                        score={data.override?.AD || data.resp.AD}
                                        riskList={riskList}
                                        risk={ADOverride}
                                        onRiskChange={setADOverride}
                                        isSuperEdit={isSuperEdit}
                                        data={data}
                                    />
                                    <Score
                                        field="FA"
                                        score={data.override?.FA || data.resp.FA}
                                        riskList={riskList}
                                        risk={FAOverride}
                                        onRiskChange={setFAOverride}
                                        isSuperEdit={isSuperEdit}
                                        data={data}
                                    />
                                </GridEven>
                                <div>
                                    {isSuperEdit ? (
                                        <OverrideScore
                                            field={overrideField}
                                            score={score}
                                            data={data}
                                            onSend={handleSend}
                                            disabled={isSendDisabled}
                                            isLoading={isLoadingOverride}
                                        />
                                    ) : (
                                        <Content title={$('PLATFORM_REPORT_TITLE')}>
                                            <Rows>
                                                <CenterAlign>
                                                    <Body2>{$('PLATFORM_REPORT_SEND')}</Body2>
                                                </CenterAlign>
                                                {/* <BtnWhatsApp>Send To Whatsapp</BtnWhatsApp> */}
                                                <SpreadEvenBase>
                                                    <SendEmail email={data.info.email} firstName={data.info.firstName} code={params.code} />
                                                    <DownloadPDF id={data._id} info={data.info} />
                                                </SpreadEvenBase>
                                            </Rows>
                                        </Content>
                                    )}

                                </div>
                            </Rows>
                        </Grid>
                    </div>
                )
                }
            </RowsMedium>
        </Main>
    )
}
