import Logo from 'Assets/SVG/logo.svg';
import LogoDM from 'Assets/SVG/logo-dm.svg';

import { ReactComponent as Circle } from "Assets/SVG/circle.svg";
import { ReactComponent as Whatsapp } from "Assets/Icons/whatsapp.svg";
import { ReactComponent as ArrowR } from 'Assets/Icons/arrow-r.svg';
import { ReactComponent as ArrowL } from 'Assets/Icons/arrow-l.svg';
import { ReactComponent as Email } from 'Assets/Icons/email.svg';
import { ReactComponent as EmailAt } from 'Assets/Icons/email-at.svg';
import { ReactComponent as Eye } from 'Assets/Icons/eye.svg';
import { ReactComponent as Pdf } from 'Assets/Icons/pdf.svg';
import { ReactComponent as Close } from 'Assets/Icons/close.svg';
import { ReactComponent as Question } from 'Assets/Icons/question.svg';
import { ReactComponent as SignOut } from 'Assets/Icons/sign-out.svg';
import { ReactComponent as Accept } from 'Assets/Icons/accept.svg';
import { ReactComponent as About } from 'Assets/SVG/about.svg';

import { ReactComponent as HighRiskFA } from 'Assets/SVG/high-risk-fa.svg';
import { ReactComponent as LowRiskFA } from 'Assets/SVG/low-risk-fa.svg';
import { ReactComponent as HighRiskAD } from 'Assets/SVG/high-risk-ad.svg';
import { ReactComponent as LowRiskAD } from 'Assets/SVG/low-risk-ad.svg';
import { ReactComponent as Check } from 'Assets/SVG/check.svg';
import { ReactComponent as ArrowDown } from 'Assets/SVG/arrowDown.svg';
import { ReactComponent as ArrowStart } from 'Assets/SVG/arrowStart.svg';
import { ReactComponent as ArrowEnd } from 'Assets/SVG/arrowEnd.svg';
import { ReactComponent as ArrowPrev } from 'Assets/SVG/arrowPrev.svg';
import { ReactComponent as ArrowNext } from 'Assets/SVG/arrowNext.svg';
import { ReactComponent as Magnify } from 'Assets/SVG/magnify.svg';
import { ReactComponent as Contact } from 'Assets/SVG/contact.svg';

import { ReactComponent as Udi } from 'Assets/SVG/udi.svg';
import { ReactComponent as Ref } from 'Assets/SVG/ref.svg';
import { ReactComponent as MD } from 'Assets/SVG/md.svg';
import { ReactComponent as Factory } from 'Assets/SVG/factory.svg';
import { ReactComponent as ECRep } from 'Assets/SVG/ec-rep.svg';
import { ReactComponent as Classified } from 'Assets/SVG/classified.svg';

import { ReactComponent as USFlag } from 'Assets/SVG/usFlag.svg';
import { ReactComponent as AUFlag } from 'Assets/SVG/auFlag.svg';
import { ReactComponent as SPFlag } from 'Assets/SVG/spainFlag.svg';
import { ReactComponent as SPmxFlag } from 'Assets/SVG/mexicoFlag.svg';
import { ReactComponent as IRLFlag } from 'Assets/SVG/irelandFlag.svg';
import { ReactComponent as RadioOn } from 'Assets/SVG/RadioOn.svg';
import { ReactComponent as CheckOn } from 'Assets/SVG/CheckOn.svg';
import { ReactComponent as SortDesc } from 'Assets/SVG/sortDesc.svg';
import { ReactComponent as SortAsc } from 'Assets/SVG/sortAsc.svg';
import { ReactComponent as Info } from 'Assets/SVG/info.svg';
import { ReactComponent as Triangle } from 'Assets/SVG/triangle.svg';

import { ReactComponent as Rolling } from 'Assets/Gif/rolling.svg';
import { ReactComponent as Query } from 'Assets/Animated/query.svg';

import { useContext } from 'react';
import { modeContext } from 'Context/modeContext';
import { Icon } from './Icon';
import { Text } from '../Typography/Typography';
import { useLang } from 'Lang/useLang';

export const IconLogo = ({ size, media }) => {
    const { mode } = useContext(modeContext);

    return (
        <Icon size={size} media={media}>
            {mode === 'dark' ? LogoDM : Logo}
        </Icon>
    )
}


export const IconText = ({
    icon,
    text,
    color
}) => {
    const { $ } = useLang();
    const getContent = () => {
        const value = $(text);

        return <Text>{value}</Text>
    }

    return (
        <div className='icon_text' data-color={color}>
            <div className='text'>{getContent()}</div>
            {icon}
        </div>
    )
}

export const IconLogoLarge = () => <IconLogo size={'logo-large'} />
export const IconLogoMedium = () => <IconLogo size={'logo-medium'} />
export const IconLogoSmall = () => <IconLogo size={'logo-small'} />

export const IconArrowR = () => <ArrowR />
export const IconArrowL = () => <ArrowL />
export const IconCircle = () => <Circle />
export const IconWhatsapp = () => <Whatsapp />
export const IconPdf = () => <Pdf />
export const IconEmail = () => <Email />
export const IconEmailAt = () => <EmailAt />
export const IconEye = () => <Eye />
export const IconClose = () => <Close />
export const IconQuestion = () => <Question />
export const IconSignOut = () => <SignOut />
export const IconAccept = () => <Accept />
export const IconCheck = () => <Check />
export const IconUsFlag = () => <USFlag />
export const IconAUFlag = () => <AUFlag />
export const IconSPFlag = () => <SPFlag />
export const IconSPmxFlag = () => <SPmxFlag />
export const IconIRLFlag = () => <IRLFlag />
export const IconArrowDown = () => <ArrowDown />
export const IconArrowStart = () => <ArrowStart />
export const IconArrowEnd = () => <ArrowEnd />
export const IconArrowPrev = () => <ArrowPrev />
export const IconArrowNext = () => <ArrowNext />
export const IconMagnify = () => <Magnify />
export const IconContact = () => <Contact />
export const IconAbout = () => <About />
export const IconRadioOn = () => <RadioOn />
export const IconCheckOn = () => <CheckOn />
export const IconSortDesc = () => <SortDesc />
export const IconSortAsc = () => <SortAsc />
export const IconInfo= () => <Info />
export const IconTriangle= () => <Triangle />

export const IconClassified = () => <Classified />
export const IconECRep = () => <ECRep />
export const IconFactory = () => <Factory />
export const IconMD = () => <MD />
export const IconRef = () => <Ref />
export const IconUdi = () => <Udi />

export const SvgHighRiskFA = () => (
    <IconText
        text="ICON_HIGH_RISK"
        icon={<HighRiskFA />}
        color={"high"}
    />
)
export const SvgLowRiskFA = () => (
    <IconText
        text="ICON_LOW_RISK"
        icon={<LowRiskFA />}
        color={"low"}
    />
)
export const SvgHighRiskAD = () => (
    <IconText
        text="ICON_HIGH_RISK"
        icon={<HighRiskAD />}
        color={"high"}
    />
)
export const SvgLowRiskAD = () => (
    <IconText
        text="ICON_LOW_RISK"
        icon={<LowRiskAD />}
        color={"low"}
    />
)
export const GifRolling = () => <Rolling />
export const GifQuery = () => (
    <div className='gif_query'>
        <Query />
    </div>
)

