import './Space.css';

export const Space = ({ children, className }) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export const Padding = (props) => {
    return <Space {...props} className="Padding" />
}

export const PaddingTop = (props) => {
    return <Space {...props} className="Padding-top" />
}

export const Margin = (props) => {
    return <Space {...props} className="Margin" />
}

export const MarginTop = (props) => {
    return <Space {...props} className="Margin-top" />
}

export const MarginBig = (props) => {
    return <Space {...props} className="Margin-big" />
} 