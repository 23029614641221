import Bg from 'Assets/SVG/bg2.svg';

import './Background.css';

export const Background = ({ children }) => {
    return (
        <div className="Background">
            <div className='back'>
               
            </div>
            <div className='content'>
                {children}
            </div>
        </div>
    )
}