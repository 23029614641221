import './Grid.css';

export const Grid = ({ children, layout, media, addClass }) => {
    return (
        <div className={`Grid ${addClass}`} layout={layout} data-media={media}>
            {children}
        </div>
    )
}

export const GridTopAuto = ({ children }) => {
    return (
        <div className='Grid top auto'>
            {children.map((c, i) => (
                <div key={i}>{c}</div>
            ))}
        </div>
    )
}

export const GridEven = (props) => {
    return <Grid {...props} addClass="even" />
}