import { RowsShort, Label2, Between, RowsStart, Subtitle1, Subtitle2 } from "UIKit";
import './Section.css';

export const Section = ({ title, children, validation, actions}) => {
    const isError = !!validation?.error;

    return (
        <div className="Section" data-validation={isError}>
            <RowsShort>
                {title && (
                    <Between>
                        <Subtitle1 bold left>{title}</Subtitle1>
                        {actions}
                    </Between>
                )}
                {children}
            </RowsShort>
            {isError && <Error error={validation?.error} />}
        </div>
    )
}

export const Error = ({ error }) => {
    if(error.list) {
        return (
            <div className="err-list">
                <Label2>{error.msg}</Label2>
                <ul>
                    {error.list.map(i => {
                        return (
                            <li 
                                key={i.msg}
                                className={i.isError ? 'is-error' : ''}
                            >
                                <Subtitle2>{i.msg}</Subtitle2>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
    return (
        <div className="err-single">
            <Label2>{error}</Label2>
        </div>
    )
}

export const Paragraph = ({ title, info}) => {
    return (
        <RowsStart>
            <Label2 bold>{title}</Label2>
            <Label2>{info}</Label2>
        </RowsStart>
    )
}