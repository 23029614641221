import { useLang } from "Lang/useLang";
import { useState } from "react";
import { BtnCard, IconEye } from 'UIKit';

export const useInputType = (initialType = 'text') => {
    const [type, setType] = useState(initialType);
    const { $ } = useLang()

    const handleSwitchPassword = () => {
        setType(type === 'password' ? 'text' : 'password');
    }

    const getIsActivePasswordClass = () => {
        if(type === 'text') {
            return "selected"
        }
        return ''
    }

    const getActions = () => {
        switch(initialType) {
            case "password": return <BtnCard addClass={getIsActivePasswordClass()} i={IconEye} onClick={handleSwitchPassword}>{$('PLACEHOLDER_SHOW')}</BtnCard>;
            default: return null;
        }
    }

    return {
        actions: getActions(),
        type
    }
}