import {
  Between,
  H4,
  Icon,
  IconArrowDown,
  IconCheck,
  Label1,
  Label2,
  Line,
  NoWrap,
} from "UIKit";
import "./Dropdown.css";
import { useEffect, useRef, useState } from "react";
import { useLang } from "Lang/useLang";
import { createPortal } from "react-dom";

export const Dropdown = ({ list, selected, onChange, isResponsive, isLang }) => {
  const [isDisp, setIsDisp] = useState(false);
  const { $ } = useLang();
  const wrapRef = useRef();
  const listRef = useRef();
  const timerRef = useRef();

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {

    if (isDisp) {
      timerRef.current = setInterval(watch, 1);
    } else {
      clearInterval(timerRef.current);
    }
  }, [isDisp])

  const watch = () => {
    const { left, right, top, width, height } = wrapRef.current.getBoundingClientRect();
    listRef.current.style.minWidth = width + 'px';
    listRef.current.style.left = left + 'px';
    listRef.current.style.top = top + height + 'px';
    listRef.current.style.display = 'block';

    const listPos = listRef.current.getBoundingClientRect();
    if(listPos.right > window.outerWidth) {
      listRef.current.style.left = right - listPos.width + 'px';
    }
  }

  const handleBodyClick = (e) => {
    if (!wrapRef.current || !listRef.current || !e.target) { return }

    if (
      !wrapRef.current.contains(e.target) &&
      !listRef.current.contains(e.target)
    ) {
      setIsDisp(false);
    }
  };

  const handleToggle = () => {
    setIsDisp(!isDisp);
  };

  const renderHeader = () => {
    if (selected !== undefined) {
      const item = list.find((i) => i.id === selected);

      if (item) {
        const value = isLang ? $(item.value) : item.value;

        if (item.icon) {
          return (
            <NoWrap>
              <span>{item.icon}</span>
              <Label2>{value}</Label2>
            </NoWrap>
          );
        }
        return <Label2>{value}</Label2>;
      }
    }
    return "...";
  };

  const handleSelect = (item) => {
    onChange(item.id);
    setIsDisp(false);
  };

  const getMedia = () => {
    if (isResponsive) {
      if (list.find(i => i.icon)) {
        return "dropdown-icon";
      }
      return "dropdown";
    }
    return ''
  }

  const renderList = () => {
    return list.map((item) => {
      const value = isLang ? $(item.value) : item.value;

      return (
        <div
          className={item.id === selected ? "selected" : ""}
          key={item.id}
          onClick={() => handleSelect(item)}
        >
          <Line>
            <span>{item.icon}</span>
            <Label1>{value}</Label1>
          </Line>
        </div>
      );
    });
  };

  const DDList = () => {
    return createPortal(
      <div className="list" ref={listRef} >{renderList()}</div>,
      document.getElementById('dropdown')
    )
  }

  return (
    <div className="Dropdown" ref={wrapRef} data-media={getMedia()}>
      <div className="header" onClick={handleToggle}>
        <Between>
          <span>{renderHeader()}</span>

          <IconArrowDown />
        </Between>
      </div>
      {isDisp && <DDList />}
    </div>
  );
};

