import { useEffect } from "react";

export const Idle = ({ onLogout, onWarning, isLogin }) => {

    const warningTimeout = 1000 * 60 * 60;
    const timeoutNow = 1000 * 60;
    let warningTimerID, timeoutTimerID;

    function startTimer() {
        warningTimerID = window.setTimeout(warningInactive, warningTimeout);
    }

    function warningInactive() {
        clearTimeout(warningTimerID);
        timeoutTimerID = window.setTimeout(IdleTimeout, timeoutNow);
        onWarning();
    }

    function resetTimer() {
        clearTimeout(timeoutTimerID);
        clearTimeout(warningTimerID);
        if (isLogin) {
            startTimer();
        }
    }

    // Logout the user.
    function IdleTimeout() {
        window.popupHide();
        onLogout();
    }

    function setupTimers() {
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, false);
        window.addEventListener("scroll", resetTimer, false);
        startTimer();
    }

    useEffect(() => {
        if (isLogin) {
            setupTimers();
        } else {
            resetTimer();
        }

        return () => {
            document.removeEventListener("mousemove", resetTimer, false);
            document.removeEventListener("mousedown", resetTimer, false);
            document.removeEventListener("keypress", resetTimer, false);
            document.removeEventListener("touchmove", resetTimer, false);
            window.removeEventListener("scroll", resetTimer, false);
        }
    }, [isLogin])

    return <></>
}