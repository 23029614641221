import { getDateFormat } from "helpers/general"

export const RISK = {
    RED: 'third',
    BLUE: 'primary',
    NONE: 'gray'
}

const conditionList = [
    { id: "atopic_dermatitis", title: "FIELD_OPTION_ATOPIC", risk: RISK.RED },
    { id: "hay_fever", title: "FIELD_OPTION_HAY_FEVER", risk: RISK.RED },
    { id: "food_allergy", title: "FIELD_OPTION_FOOD_ALLERGY", risk: RISK.RED},
    { id: "asthma", title: "FIELD_OPTION_ASTHMA", risk: RISK.RED },
    { id: "not_sure", title: "FIELD_OPTION_NOT_SURE", risk: RISK.NONE },
    { id: "none", title: "FIELD_OPTION_NONE", risk: false, isNone: RISK.BLUE },
]

const numList = [
    { id: 0, title: "FIELD_ZERO", risk: false },
    { id: 1, title: "FIELD_ONE", risk: false },
    { id: 2, title: "FIELD_TWO", risk: true },
    { id: 3, title: "FIELD_THREE", risk: true },
    { id: 4, title: "FIELD_FOUR_PLUS", isGreaterEqual: true, risk: true }
]

const environmentList = [
    { id: 'city', title: "FIELD_URBAN", risk: RISK.RED },
    { id: 'suburbs', title: "FIELD_SUBURB", risk: RISK.NONE },
    { id: 'countryside', title: "FIELD_RURAL", risk: RISK.BLUE }
]

const yesNoList = [
    { id: false, title: "FIELD_NO", risk: false },
    { id: true, title: "FIELD_YES", risk: true }
]

const formulaList = [
    { id: "none", title: "FIELD_NO", risk: RISK.NONE },
    { id: "first_few_days", title: "FIELD_IN_HOSPITAL", risk: RISK.NONE },
    { id: "zero_one", title: "FIELD_ZERO_ONE", risk: RISK.NONE },
    { id: "one_two", title: "FIELD_ONE_TWO", risk: RISK.NONE },
    { id: "two_three", title: "FIELD_TWO_THREE", risk: RISK.NONE },
    { id: "three_four", title: "FIELD_THREE_FOUR", risk: RISK.NONE },
    { id: "four_plus", title: "FIELD_FOUR_PLUS", risk: RISK.NONE },
]

const isBorn = (data) => {
    return data.info.infantAge !== 'no_born';
}


export const answersSections = {
    baby: {
        key: "ANS_BABY_HEADER",
        grid: "triple",
        list: [
            {
                title: "ANS_INFANT_NAME",
                key: 'info.infantFirstName',
                cond: (data, $) => {
                    return {
                        value: isBorn(data) && data.info.infantFirstName ? `${data.info.infantFirstName} ${data.info.lastName}` : $('PLATFORM_INFO_NA')
                    }
                }
            },
            {
                title: "ANS_DOB",
                key: 'info.infantDateOfBirth',
                info: 'INFO_DOB',
                type: 'date',
                cond: (data, $) => {
                    const date = !isBorn(data) ? data.info.infantDueDateOfBirth : data.info.infantDateOfBirth;
                    const getDate = () => {
                        return getDateFormat(date, data.data.dateFormat);
                    }

                    return {
                        title: !isBorn(data) ? 'DUE_DATE_OF_BIRTH' : 'PLATFORM_INFO_BIRTH',
                        value: getDate(),
                        risk: [1,2,9,10,11,12].includes(new Date(date).getMonth + 1) ? RISK.RED : RISK.BLUE
                    }
                }
            },
            {
                title: "PLATFORM_INFO_GENDER",
                info: 'INFO_GENDER',
                key: 'info.gender',
                cond: (data, $) => {
                    const isMale = data.info.gender === 'male' || data.info.genderKnown === 'boy';

                    return {
                        value: isMale ? $('FIELD_KEY_MALE') : $('FIELD_KEY_FEMALE'),
                        risk: isMale ? RISK.BLUE : RISK.RED
                    }
                }
            },
            {
                title: "PLATFORM_INFO_WEIGHT",
                info: 'INFO_WEIGHT',
                key: 'ml.birth_weight',
                cond: (data, $) => {
                    const getWeight = () => {
                        if (data.data.weight === 'pounds') {
                            return data.info.weightPound;
                        }
                        return data.info.weightKg;
                    }

                    return {
                        value: isBorn(data) ? `${getWeight()} ${data.data.weight}` : $('PLATFORM_INFO_NA')
                    }
                }
            },
            {
                title: "ANS_GESTATIONALAGE",
                key: 'info.gestationalAge',
                info: 'INFO_GESTATIONALAGE',
                cond: (data) => {
                    return {
                        risk: data.info.gestationalAge < 37  ? RISK.BLUE : RISK.NONE,
                        hide: !isBorn(data)
                    }
                }
            },
            {
                title: "ANS_CESARIAN",
                key: 'ml.born_by_caesarian',
                info: 'INFO_CESARIAN',
                type: 'boolean',
                cond: (data) => {
                    return {
                        risk: data.ml.born_by_caesarian ? RISK.RED : RISK.BLUE,
                        hide: !isBorn(data)
                    }
                }
            },
            {
                title: "ANS_ATOPIC_SIGNS",
                info: "INFO_ATOPIC_SIGNS",
                key: 'ml.atopic_signs',
                type: 'boolean',
                cond: (data) => {
                    return {
                        risk: data.ml.atopic_signs ? RISK.RED : RISK.BLUE
                    }
                }
            },
            {
                title: "ANS_ATOPIC_DIAGNOSE",
                info: "INFO_ATOPIC_DIAGNOSE",
                key: 'ml.atopic_diagnose',
                type: 'boolean'
            },
            {
                title: "ANS_ANTIBIOTICS_PRESCRIBED",
                info: "INFO_ANTIBIOTICS_PRESCRIBED",
                key: 'ml.systemic_antibiotics_prescribed',
                type: 'boolean',
                cond: (data) => {
                    return {
                        risk: data.ml.systemic_antibiotics_prescribed ? RISK.RED : RISK.BLUE
                    }
                }
            },
            {
                title: "ANS_TOPICAL_ANTIBIOTICS_PRESCRIBED",
                info: "INFO_TOPICAL_ANTIBIOTICS_PRESCRIBED",
                key: 'ml.topical_antibiotics_prescribed',
                type: 'boolean',
                cond: (data) => {
                    return {
                        risk: data.ml.topical_antibiotics_prescribed ? RISK.RED : RISK.BLUE
                    }
                }
            },
        ]
    },
    familyHistory: {
        key: "ANS_FAMILY_HISTORY_HEADER",
        grid: "even",
        list: [
            {
                title: "ANS_MATERNAL_ATOPIC",
                info: "ANS_MATERNAL_ATOPIC_TOOLTIP",
                options: conditionList,
                key: 'ml.mother_atopic_cond'
            },
            {
                title: "ANS_PARENTAL_ATOPIC",
                info: "ANS_PARENTAL_ATOPIC_TOOLTIP",
                options: conditionList,
                key: 'ml.father_atopic_cond'
            },
            {
                title: "ANS_SIBLINGS_ATOPIC",
                info: "ANS_SIBLINGS_ATOPIC_TOOLTIP",
                options: conditionList,
                key: 'ml.siblings_atopic_cond'
            },
            {
                title: "ANS_SIBLINGS_NUM",
                info: "ANS_SIBLINGS_NUM_TOOLTIP",
                options: numList,
                key: 'ml.siblings_num'
            },
            {
                title: "ANS_ANTIBIOTICS_SUBS",
                key: 'ml.mother_antibiotics_pregnancy',
                info: 'INFO_ANTIBIOTICS_SUBS',
                options: numList,
                cond: (data) => {
                    return {
                        risk: data.ml.mother_antibiotics_pregnancy ? RISK.RED : RISK.BLUE                    }
                }
            }
        ]
    },
    environment: {
        key: "ANS_ENVIRONMENT_HEADER",
        grid: "even",
        list: [
            {
                title: "ANS_NEIGHBORHOOD",
                info: "ANS_NEIGHBORHOOD_TOOLTIP",
                options: environmentList,
                key: 'ml.birth_place'
            },
            {
                title: "ANS_SMOKING",
                info: "ANS_SMOKING_TOOLTIP",
                key: 'ml.smoking',
                type: 'boolean',
                cond: (data) => {
                    return {
                        risk: data.ml.smoking ? RISK.RED : RISK.BLUE
                    }
                }
            }
        ]
    },
    dietaryHistory: {
        key: "ANS_DIETARY_HISTORY_HEADER",
        grid: "even",
        list: [
            {
                title: "ANS_BREASTFED",
                info: "ANS_BREASTFED_TOOLTIP",
                options: yesNoList,
                key: 'ml.breastfed'
            },
            {
                title: "ANS_SPECIAL_FORMULA_USAGE",
                info: "ANS_SPECIAL_FORMULA_USAGE_TOOLTIP",
                options: formulaList,
                key: 'info.specialFormula'
            },
            {
                title: "ANS_FORMULA_USAGE",
                info: "ANS_FORMULA_USAGE_TOOLTIP",
                options: formulaList,
                key: 'info.infantFormula'
            },
        ]
    }
}