import { useEffect, useState } from "react"

export const Responsive = ({ wide, med, thin, small }) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleSetWidth);

        return () => {
            window.removeEventListener('resize', handleSetWidth);
        }

    }, [])

    const handleSetWidth = () => {
        setWidth(window.innerWidth);
    }

    const isMed = med && width < 900;
    const isSmall = small && !isMed && width < 800;
    const isThin = thin && !isMed && !isSmall && width < 500;
    const isWide = !isMed && !isSmall && !isThin;

    return (
        <>
         {isMed && med}
         {isThin && thin}
         {isWide && wide}
        </>
    )
}