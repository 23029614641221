import './Inner.css';

export const Inner = ({ children, addClass }) => {
    return (
        <div className={`Inner ${addClass || ''}`}>
            {children}
        </div>
    )
}

export const InnerSide = (props) => {
    return <Inner {...props} addClass="side" />
}

export const InnerThin = (props) => {
    return <Inner {...props} addClass="thin" />
}

export const InnerSmall = (props) => {
    return <Inner {...props} addClass="small" />
}

export const InnerMed = (props) => {
    return <Inner {...props} addClass="med" />
}
