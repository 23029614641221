import { Body1, Body2, CheckBox, Content, Grid, Info, Line, NoWrap, Rows, RowsShort, Short } from "UIKit"
import { RISK, answersSections } from "./Answers.model"
import { useLang } from "Lang/useLang";

export const Answers = ({ data }) => {
    const { $ } = useLang();

    const getCheckedStrip = (section) => {
        const strip = section.list.map(field => {
            const { 
                hide, parent, key, 
                options, risk, value 
            } = getValues(field);

            if(hide) { return }


            if (options) {
                return options.map(option => {
                    let checked = Array.isArray(data[parent][key]) ? data[parent][key].includes(option.id) : data[parent][key] === option.id;
                    if (Array.isArray(data[parent][key])) {
                        if (!data[parent][key].length && option.isNone) {
                            checked = true;
                        }
                    }
                    if (checked && option.risk != RISK.NONE) {
                        return (
                            <CheckBox className="short" key={option.title} color={option.risk} item={{
                                checked: checked
                            }} />
                        )
                    }

                })
            }

            if (field.cond) {
                if (risk && risk !== RISK.NONE) {
                    return (
                        <CheckBox className="short" key={value} color={risk} item={{
                            checked: true
                        }} />
                    )
                }
            }
        }).filter(Boolean);

        return strip.length ? (
            <Short>
                {strip}
            </Short>
        ) : <></>
    }

    const getValues = (field) => {
        const [parent, key] = field.key.split('.');
        const override = field.cond ? field.cond(data, $) : {};

        let value = data[parent][override.key || key];
        if(field.type === 'boolean' ) {
            value = value && value !== 'no' ? $('FIELD_YES') : $('FIELD_NO');
        }
        
        
        const obj = {
            parent, 
            key,
            value,
            title: field.title,
            info: field.info,
            options: field.options,
            selectedRisk: null
        }

        return {...obj, ...override}
    }


    return (
        <Rows>
            {Object.keys(answersSections).map((key, index) => {
                const section = answersSections[key];

                return (
                    <Content
                        key={section.key}
                        title={$(section.key)}
                        collapsible={true}
                        open={!index}
                        strip={getCheckedStrip(section)}
                    >

                        <Grid addClass={section.grid} media="answers">
                            {section.list.map(field => {
                                const { 
                                    hide, parent, key, title, info, 
                                    options, selectedRisk, value 
                                } = getValues(field);
                                if(hide) { return }

                                return (
                                    <RowsShort key={key}>
                                        <NoWrap>
                                            <Body1 bold>{$(title)}</Body1>
                                            {info && <Info text={$(info)} />}
                                        </NoWrap>
                                        {
                                            options ? (
                                                <Short>
                                                    {
                                                        options.map(option => {
                                                            let checked = Array.isArray(data[parent][key]) ? data[parent][key].includes(option.id) : data[parent][key] === option.id;
                                                            if (Array.isArray(data[parent][key])) {
                                                                if (!data[parent][key].length && option.isNone) {
                                                                    checked = true;
                                                                }
                                                            }
                                                            return (
                                                                <CheckBox key={option.title} color={option.risk} item={{
                                                                    title: option.title,
                                                                    checked: checked
                                                                }} />
                                                            )
                                                        })
                                                    }
                                                </Short>
                                            ) : selectedRisk && selectedRisk !== RISK.NONE ? (
                                                <Line>
                                                    <CheckBox key={value} color={selectedRisk} item={{
                                                        checked: true
                                                    }} />
                                                     <Body2>{value}</Body2>
                                                </Line>
                                            ) : (
                                                <Body2>{value}</Body2>
                                            )
                                        }
                                    </RowsShort>
                                )
                            })}
                        </Grid>

                    </Content>
                )
            })}
        </Rows>
    )
}