import { SepLine, Padding, Center, Short, H3 } from "UIKit"
import { userContext } from "Context/userContext";
import { useContext } from "react";
import { useLang } from "Lang/useLang";

export const Header = () => {
    const { $ } = useLang();
    const { data } = useContext(userContext);

    if (!data) { return <></> }

    return (
        <div>
            <Padding>
                <Center>
                    <Short>
                        <H3 light>{$('PATIENT_HEADER_TITLE')}</H3>
                        <H3 bold>{data.firstName}</H3>
                    </Short>
                </Center>
            </Padding>

            <SepLine />
        </div>
    )
}