import { useLang } from "Lang/useLang";
import { Modal } from "Shared/Modal/Modal";
import {
  Background,
  Between,
  BtnSecondary,
  Center,
  CenterAlign,
  Dropdown,
  H1,
  H4,
  IconAbout,
  IconClose,
  IconContact,
  IconLogoLarge,
  IconLogoMedium,
  IconLogoSmall,
  InnerMed,
  InnerSmall,
  InnerThin,
  Line,
  MarginBig,
  MarginTop,
  MarkdownShow,
  Padding,
  PaddingTop,
  Responsive,
  Rows,
  RowsFullHigh,
  SepLine,
  SmallText,
} from "UIKit";
import { Absolute } from "UIKit/Layouts/Absolute/Absolute";
import { DoctorNotes } from "Views/DoctorNotes";
import { Legals } from "./Legals/Legals";
import { useMarkdown } from "Hooks/useMarkdown";
import { IconRound } from "UIKit/Elements/Icon/Icon";

export const LoginWrapper = ({ title, info, children }) => {
  const { $, dd } = useLang();

  const fetch = useMarkdown('ABOUT_MYORCARE');

  const showAbout = () => {
    window.popup({
      elem: (
        <InnerMed>
          <Rows>
            <Padding>
              <Between>
                <H4>{$('ABOUT_MYORCARE_TITLE')}</H4>
                <IconRound i={IconClose} onClick={window.popupHide} />
              </Between>
            </Padding>
            <SepLine />
            <MarkdownShow fetch={fetch} />
            <SepLine />
            <Padding>
              <Legals />
            </Padding>
            <SepLine />
            <Padding>
              <Center>
                <SmallText gray light>{$('ABOUT_RIGHTS')}</SmallText>
              </Center>
            </Padding>
          </Rows>
        </InnerMed>
      )
    });
  }

  const handleContact = () => {
    window.popup({ elem: <DoctorNotes /> });
  };
  return (
    <Background>
      <Modal />
      <Absolute side="tl">
        <Responsive
          wide={
            <Padding>
              <BtnSecondary isResponsive onClick={showAbout} i={IconAbout} >{$('HEADER_ABOUT')}</BtnSecondary>
            </Padding>
          }
          med={(
            <Padding>
              <Line>
              <IconLogoSmall />
              <BtnSecondary onClick={showAbout} i={IconAbout} />
              </Line>
            </Padding>
          )}
        />
      </Absolute>
      <Absolute side="tr">
        <Line>
          <Dropdown isResponsive {...dd()} />
          <Responsive
            wide={(
              <BtnSecondary onClick={handleContact}>
                {$("BTN_CONTACT_US")}
              </BtnSecondary>
            )}
            med={(
              <BtnSecondary i={IconContact} onClick={handleContact} />
            )}
          />
        </Line>
      </Absolute>
      <Center>
        <Rows>
          <MarginBig>
            <Center>
              <PaddingTop>
                <Responsive
                  wide={<IconLogoLarge />}
                  med={<></>}
                />
              </PaddingTop>
            </Center>
          </MarginBig>
          <CenterAlign>
            <Rows>
              <H1>{title}</H1>
              <InnerThin>
                <H4 center>{info}</H4>
              </InnerThin>
              <MarginTop>
                <Center>{children}</Center>
              </MarginTop>
            </Rows>
          </CenterAlign>
        </Rows>
      </Center>
    </Background>
  );
};
