import { Line, Section, SpreadEnd } from 'UIKit';
import './Input.css';
import { useInputType } from './hooks/usePassword';
import { forwardRef, useEffect, useRef, useState } from 'react';

export const Input = forwardRef((props, ref) => {
    const { type, actions } = useInputType(props.type);
    const { p: placeholder, numeric, i, min, max, variant, empty, onChange, validation, title, focus, ...rest } = props;
    const inputRef = useRef();


    useEffect(() => {
        if (focus) {
            inputRef.current.focus();
        }
    }, [])



    const handleChange = (e) => {
        handleNumeric(e);

        onChange(e.target.value);
    }

    const handleNumeric = (e) => {
        if (numeric) {
            if (isNaN(e.target.value)) { e.target.value = rest.value; }
            if (!e.target.value) { e.target.value = min ? min : 0 }
            if (min !== undefined && +e.target.value < min) { e.target.value = min }
            if (max !== undefined && +e.target.value > max) { e.target.value = max }
        }

    }

    const handleFocus = () => {
        setTimeout(() => {
            if (numeric) {
                inputRef.current.select();
            }
        }, 1)
    }

    return (
        <Section validation={validation} title={title} actions={actions}>
            <div className='Input' data-variant={variant}>
                <SpreadEnd>
                    {i && i()}
                    <input
                        ref={inputRef}
                        {...rest}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        type={type}
                    />
                </SpreadEnd>
            </div>
        </Section>
    )
})

export const InputRound = (props) => {
    return <Input {...props} variant="round" />
}