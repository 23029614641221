import { H3, H4, IconArrowDown, IconCircle, Line } from 'UIKit';
import './Content.css';
import { useState } from 'react';

export const Content = ({ title, children, theme, strip, open = false, collapsible = false }) => {
    const [isOpen, setIsOpen] = useState(open);

    const handleOpenToggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <section className='Content' data-no-title={!title} data-collapsible={collapsible} data-is-open={isOpen} data-theme={theme}>
            <div className='back'>
                <div></div>
                <div></div>
            </div>
            {title && (
                <div className='header-wrap'>
                    <div className='header'>
                        <Line>
                              <H4>{title}</H4>
                              {!isOpen && strip}
                        </Line>
                    </div>
                    <IconCircle />
                    {collapsible && (
                        <div className='icon' onClick={handleOpenToggle}>
                            <IconArrowDown />
                        </div>
                    )}
                </div>
            )}
            <div className='body'>
                {children}
            </div>
        </section>
    )
}