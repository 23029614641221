
import { useEffect, useState } from 'react';
import './MarkdownShow.css';
import { Center, Rows, Text } from 'UIKit';
import { GifRolling } from '../Icon';
import { useLang } from 'Lang/useLang';


export const MarkdownShow = ({ fetch }) => {
    const [html, setHtml] = useState('');
    const [loading, setLoading] = useState(true);
    const { $ } = useLang();

    useEffect(() => {
        fetch().then((resp) => {
            setTimeout(() => {
                setHtml(resp.replace(/\<table/gi, '<div class="table-wrapper"><table').replace(/\<\/table\>/gi, '</table></div>'));
                setLoading(false);
            }, 800)
        });
    }, [])

    return (
        <>
            <div
                className='MarkdownShow'
                dangerouslySetInnerHTML={{ __html: html }}
            />
            {loading && (
                <Center>
                    <Rows>
                        <GifRolling />
                        <Text center>{$('GLOBAL_LOADING')}</Text>
                    </Rows>
                </Center>
            )}
        </>
    )
}