import { useLang } from "Lang/useLang";
import {
  Background,
  Between,
  BtnLink,
  BtnSecondary,
  Dropdown,
  Grid,
  IconLogoMedium,
  IconLogoSmall,
  IconQuestion,
  IconSignOut,
  Line,
  Responsive,
} from "UIKit";
import { Inner } from "UIKit/Layouts/Inner/Inner";
import { DoctorNotes } from "Views/DoctorNotes";
import { useNavigate } from "react-router-dom";

export const Main = ({ children }) => {
  const navigate = useNavigate();
  const { $, dd } = useLang();

  const handleSignOut = async () => {
    const isOk = await window.conf(
      $("MAIN_CONF_SING_OUT"),
      $("MAIN_CONF_SING_OUT_TITLE")
    );
    if (isOk) {
      navigate("/login");
    }
  };

  const handleContact = () => {
    window.popup({ elem: <DoctorNotes /> });
  };

  return (
    <Background>
      <Grid layout="main">
        <header>
          <Inner>
            <Between>
              <Responsive 
                  wide={<IconLogoMedium />}
                  med={<IconLogoSmall />}
              />

              <Line>
                <BtnLink isResponsive onClick={handleContact} i={IconQuestion}>
                  {$("BTN_SUPPORT")}
                </BtnLink>
                <Dropdown isResponsive {...dd()} />
                <BtnSecondary
                  isResponsive
                  onClick={handleSignOut}
                  isNextIcon={IconSignOut}
                >
                  {$("BTN_SIGN_OUT")}
                </BtnSecondary>
              </Line>
            </Between>
          </Inner>
        </header>
        <main>
          <Inner>{children}</Inner>
        </main>
      </Grid>
    </Background>
  );
};
