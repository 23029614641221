import { useLang } from 'Lang/useLang';
import './Table.css';
import { IconSortAsc, IconSortDesc, NoWrap } from 'UIKit';

export const Table = ({
    columns = {},
    list = [],
    map = {},
    sort = {
        column: '_id',
        isDesc: true,
        onChange: () => { }
    }
}) => {
    const { $ } = useLang();

    const handleSort = (column) => {
        if (column === sort.column) {
            sort.onChange({
                column,
                isDesc: !sort.isDesc
            });
        } else {
            sort.onChange({
                column,
                isDesc: true
            });
        }
    }

    return (
        <div className='Table'>
            <div>
                <table>
                    <thead>
                        <tr>
                            {
                                Object.keys(columns).map(key => (
                                    <td key={key} onClick={() => handleSort(key)}>
                                        <div data-is-sort={sort.column === key}>
                                            <NoWrap>
                                                {$(columns[key])}
                                                <div className='sort'>
                                                    {sort.column === key && (
                                                        <>
                                                            {sort.isDesc ? (
                                                                <IconSortDesc />
                                                            ) : (
                                                                <IconSortAsc />
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </NoWrap>
                                        </div>
                                    </td>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.map((item) => (
                                <tr key={item.id}>
                                    {
                                        Object.keys(item).map((key) => {
                                            if (!columns[key]) return null;

                                            const value = map[key] ? map[key](item) : item[key];
                                            return (
                                                <td key={key}>{value}</td>
                                            )
                                        }).filter(Boolean)
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}