import { useLang } from "Lang/useLang"
import { BtnEmail, Rows } from "UIKit";
import { api, routes } from "helpers/api";
import { useState } from "react";

export const SendEmail = ({ email, code, firstName }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { $ } = useLang();


    const handleSendEmail = async () => {
        const isOk = await window.conf((
            $('PLATFORM_CONF', <b>{email}</b>, firstName)
        ), $('PLATFORM_CONF_TITLE'));
        if (isOk) {
            setIsLoading(true);
            try {
                await api.post(routes.resultsAdmin + `/send/byemail/${code}`)
                window.toast($('PLATFORM_TOAST_SENT'));
            } catch (e) {
                const message = api.getError(e);
                window.toastError(message);

            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Rows>
            <BtnEmail isLoading={isLoading} onClick={handleSendEmail}>{$('PLATFORM_REPORT_BTN_EMAIL')}</BtnEmail>
        </Rows>
    )
}