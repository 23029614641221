import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { useLang } from "Lang/useLang";
import { BtnNext, Input, Label2, Middle, RoundBox, RowsMedium, RowsShort } from "UIKit"
import { LoginWrapper } from "Wrapper/LoginWrapper";
import { api, routes } from "helpers/api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"

const STATUS = {
    LOADING: 0,
    VALID: 1,
    USED: 2,
    ERROR: 3
}

export const WelcomePassword = () => {
    const { $, setLang, isOn } = useLang();
    const { code, email } = useParams();
    const { search } = useLocation();

    const [status, setStatus] = useState(STATUS.LOADING);

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!isOn) { return }

        const urlParams = new URLSearchParams(search);

        const lang = urlParams.get('lang');
        if (lang) {
            setLang(+lang);
        }
    }, [isOn])

    useEffect(() => {
        api.get(routes.resultsAdmin + '/validate', {
            code,
            email
        })
            .then(resp => {
                setUser(resp);
                setStatus(STATUS.VALID);
            })
            .catch(err => {
                const { data = 'error' } = err?.response;

                switch (data) {
                    case STATUS.USED:
                        setStatus(STATUS.USED);
                        break;
                    default:
                        setStatus(STATUS.ERROR);
                        break;

                }
            })
    }, [])

    const [isDone, setIsDone] = useState(false);

    const navigate = useNavigate();
    const Password = useInput('', $('WELCOME_PASS_PLCAHOLDER'), 'password');

    const validate = useValidator(Password);

    const handleSetPassword = () => {
        if (!validate()) { return }

        api.post(routes.resultsAdmin + '/set-password', {
            email, code, password: Password.value
        }).then(resp => {
            setTimeout(handleNavigate, 5000);
            setIsDone(true);
        }).catch(err => {
            setStatus(STATUS.ERROR)
        })


    }

    const handleNavigate = () => {
        navigate('/login');
    }

    const navigateLogin = () => {
        navigate('/login');
    }

    const isDisabled = !Password.value;

    if (status === STATUS.LOADING) { return <></> }

    if (status === STATUS.USED) {
        return (
            <LoginWrapper
                title={$('WELCOME_STATUS_USED_TITLE')}
                info={$('WELCOME_STATUS_USED_INFO')}
            >
                <BtnNext onClick={navigateLogin}>Login</BtnNext>
            </LoginWrapper>
        )
    }

    if (status === STATUS.ERROR) {
        return (
            <LoginWrapper
                title={$('WELCOME_STATUS_ERROR_TITLE')}
                info={$('WELCOME_STATUS_ERROR_INFO')}
            >
                <BtnNext onClick={navigateLogin}>Login</BtnNext>
            </LoginWrapper>
        )
    }

    return (
        <LoginWrapper
            title={$('WELCOME_TITLE', user?.firstName)}
            info={$('WELCOME_INFO')}
        >
            <RoundBox decor={false}>
                <Middle>

                    {isDone ? (
                        <RowsMedium>
                            <Label2>
                                {$('WELCOME_REDIRECTED')}
                            </Label2>

                            <BtnNext onClick={handleNavigate}>{$('WELCOME_BTN_SIGNIN')}</BtnNext>
                        </RowsMedium>

                    ) : (
                        <RowsMedium>
                            <RowsShort>
                                <Input focus title={$('PAGE_LOGIN_FIELD_PASSWORD')} type="password" {...Password} />
                            </RowsShort>

                            <BtnNext disabled={isDisabled} onClick={handleSetPassword}>{$('WELCOME_BTN_CONTINUE')}</BtnNext>
                        </RowsMedium>

                    )}
                </Middle>
            </RoundBox>
        </LoginWrapper>

    )
}