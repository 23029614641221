import { useEffect, useRef, useState } from 'react';
import { IconInfo, IconTriangle } from '../Icon';
import './Info.css';
import { createPortal } from 'react-dom';

export const Info = ({ text }) => {
    const [isDisp, setIsDisp] = useState(false);
    const wrapRef = useRef();
    const screenRef = useRef();
    const timerRef = useRef();

    useEffect(() => {

        if (isDisp) {
            timerRef.current = setInterval(watch, 1);
        } else {
            clearInterval(timerRef.current);
        }
    }, [isDisp])

    const watch = () => {
        const { left, width, bottom } = wrapRef.current.getBoundingClientRect();
        screenRef.current.style.left = left + (width / 2) + 'px';
        screenRef.current.style.top = bottom + 10 + 'px';
        screenRef.current.style.zIndex = 1000;
    }

    const Screen = () => {
        return createPortal(
            <div className="screen" ref={screenRef} >
                <div className='triangle'><IconTriangle /></div>
                {text}
            </div>,
            document.getElementById('info')
        )
    }

    return (
        <div
            className='Info'
            onMouseEnter={() => setIsDisp(true)}
            onMouseLeave={() => setIsDisp(false)}
            ref={wrapRef}
            data-is-disp={isDisp}
        >
            <IconInfo />
            {isDisp && (
                <Screen />
            )}
        </div>
    )
}