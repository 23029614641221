import './App.css';
import { useContext } from 'react';
import { modeContext } from 'Context/modeContext';

export const App = () => {
    const { mode } = useContext(modeContext);

    return (
        <div className="App" data-mode={mode}>
          
        </div>
    )
}