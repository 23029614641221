import Decor from 'Assets/SVG/decor.svg';

import './RoundBox.css';

export const RoundBox = ({ children, decor = true }) => {
    return (
        <div className='RoundBox' data-decor={decor}>
            {decor && (
                <div className='decor'>
                    <img src={Decor} alt='' />
                </div>
            )}
            <div className='content'>
                {children}
            </div>
        </div>
    )
}