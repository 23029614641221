import './Icon.css';

export const Icon = ({ children, size = "regular", media }) => {
    return (
        <div className="Icon" data-size={size} data-media={media}>
            <img alt='' src={children} />
        </div>
    )
}

export const IconRound = ({ i, onClick }) => {
    return (
        <div className="Icon" data-shape={'round'} onClick={onClick} data-clickable={onClick ? true : false}>
            {i()}
        </div>
    )
}

export const IconRoundBig = ({ i, theme }) => {
    return (
        <div className="Icon big" data-shape={'round'} data-theme={theme}>
            {i()}
        </div>
    )
}

export const Dot = ({ color }) => {
    return <div className='Dot' data-color={color}></div>
}