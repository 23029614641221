import { useLang } from 'Lang/useLang';
import { useId } from 'react';
import { IconRadioOn, IconCheckOn } from 'UIKit';
import './CheckBox.css';


export const CheckBox = ({ type = 'checkbox', item, color, theme, onChange, validation, onClick, className, name, disabled, error }) => {
    const forId = useId();
    const { $ } = useLang();
    const checked = !!item.checked;
    const isError = !!validation?.error;

    const handleChange = (e) => {
        onChange && onChange(item, e.target.checked);
    }

    const Icon = type === 'checkbox' ? IconCheckOn : IconRadioOn;
    const isActionInside = item.titleActions;

    return (
        <label
            data-error={error}
            data-disabled={disabled}
            data-type={type}
            data-checked={checked}
            htmlFor={forId}
            className={`CheckBox ${className || ''}`}
            data-color={color}
            data-theme={theme}
            data-validation={isError}
            data-no-hover={isActionInside}
        >
            <div className='icon_wrap' >
                {checked && <Icon />}
            </div>
            {item.title && <div onClick={onClick}>{$(item.title, ...(item.titleArgs || []))}</div>}
            <input
                id={forId}
                type={type}
                checked={checked}
                name={name}
                disabled={disabled}
                onChange={handleChange}
            />
        </label>
    )
}

export const RadioBtn = (props) => {
    return <CheckBox {...props} type='radio' />
}

export const CheckBoxAction = (props) => {
    return <CheckBox {...props} theme='action' />
}