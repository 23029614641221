import { userContext } from "Context/userContext"
import { useLang } from "Lang/useLang"
import { Modal } from "Shared/Modal/Modal"
import { Text, BtnLow, H1, Line, RowsMedium, Table, End, Input, IconMagnify, GifRolling, GifQuery, NoWrap } from "UIKit"
import { Dot } from "UIKit/Elements/Icon/Icon"
import { Idle } from "UIKit/Elements/Idle/Idle"
import { Main } from "Wrapper/Main"
import { api, routes } from "helpers/api"
import { getDateFormat } from "helpers/general"
import { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Paging } from "./Paging"
import { useInput } from "Hooks/useInput"

export const Dashboard = () => {
    const [data, setData] = useState({ list: [], total: 0 });
    const [isLoading, setIsLoading] = useState(true);

    const PagingState = useInput(1);
    const Query = useInput("", "Search By Patient");
    const [sortBy, setSortBy] = useState({ column: 'created', isDesc: true });

    const { data: userData, user, logout } = useContext(userContext);
    const { $ } = useLang();
    const timer = useRef();

    useEffect(() => {
        setIsLoading(true);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            api.get(routes.ml + '/dashboard', {
                limit: 7,
                page: PagingState.value,
                query: Query.value,
                sortBy: sortBy.column,
                sortDir: sortBy.isDesc ? -1 : 1
            })
                .then((resp) => {
                    setData(resp);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }, 500)

        return () => {
            clearTimeout(timer.current);
        }
    }, [PagingState.value, Query.value, sortBy])

    const handleWarning = () => {
        window.popup({ msg: $('GLOBAL_IDLE') })
    }

    return (
        <Main>
            <Idle isLogin={!!user} onLogout={logout} onWarning={handleWarning} />
            <Modal />
            <RowsMedium>
                <Line>
                    <H1 light>{$('PLATFORM_TITLE')}</H1>
                    {userData && (
                        <H1>{userData.firstName}</H1>
                    )}
                </Line>
                <Line>
                    <Input {...Query} i={IconMagnify} variant="thin" />
                    {isLoading && <GifQuery />}
                </Line>
                <Table
                    columns={{
                        firstName: 'DASHBOARD_FIRST_NAME',
                        lastName: 'DASHBOARD_LAST_NAME',
                        phoneNumber: 'DASHBOARD_PHONE_NUMBER',
                        email: 'DASHBOARD_EMAIL',
                        ...data.isState ? { state: "DASHBOARD_STATE" } : {},
                        domain: 'DASHBOARD_DOMAIN',
                        created: 'DASHBOARD_CREATED_AT',
                        ga: 'DASHBOARD_GA',
                        AD: 'DASHBOARD_AD',
                        FA: 'DASHBOARD_FA',
                        code: 'DASHBOARD_CODE',
                        short: 'DASHBOARD_SHORT',
                        
                    }}
                    map={{
                        created: (item) => getDateFormat(item.created),
                        AD: (item) => {
                            return (
                                <NoWrap>
                                    <Dot color={item.AD} />
                                    <Text>{$(`DASHBOARD_RISK_` + item.AD.toUpperCase())}</Text>
                                </NoWrap>
                            )
                        },
                        FA: (item) => {
                            return (
                                <NoWrap>
                                    <Dot color={item.FA} />
                                    <Text>{$(`DASHBOARD_RISK_` + item.FA.toUpperCase())}</Text>
                                </NoWrap>
                            )
                        },
                        short: (item) => (
                            <Link to={`/platform/${item.short}`}>
                                <BtnLow>{$('DASHBOARD_ACTION')}</BtnLow>
                            </Link>
                        )
                    }}
                    list={data.list}
                    sort={{
                        ...sortBy,
                        onChange: setSortBy
                    }}
                />
                <End>
                    <Paging
                        state={PagingState}
                        total={data.total}
                        totalPages={data.totalPages}
                    />
                </End>
            </RowsMedium>
        </Main>
    )
}