import { Between, Text, GifRolling, IconArrowR, IconEmail, IconPdf, IconWhatsapp, Label1, Line, NoWrap, Rows, RowsStart } from "UIKit";
import { Button } from "../Typography/Typography"
import './Btn.css';
import { Icon, IconRound, IconRoundBig } from "../Icon/Icon";

export const Btn = ({ disabled, onClick, children, i, isNext, isResponsive, isLoading, isNextIcon, type, addClass }) => {
    const isDisabled = disabled;

    const renderChild = () => {
        return (
            <NoWrap>
                {i && i()}
                {children && (
                    <div className="text">
                        {children}
                    </div>
                )}
            </NoWrap>
        )
    }

    const getClass = () => {
        const cls = ['Btn'];
        if (addClass) {
            cls.push(addClass);
        }
        if (!children) {
            cls.push('no-children');
        }

        if (isLoading) {
            cls.push('loading');
        }

        return cls.join(' ');
    }

    const handleClick = (e) => {
        if (isLoading || !onClick) { return }

        onClick(e);
    }
    return (
        <Button onClick={handleClick} className={getClass()} type={type} disabled={isDisabled} media={isResponsive ? 'btn' : null}>
            {isNext || isNextIcon ? (
                <Between>
                    {renderChild()}
                    <div className="actions-wrap">
                        <div className="loader">
                            <GifRolling />
                        </div>
                        <div className="next-icon">
                            {isNextIcon ?
                                isNextIcon()
                                :
                                <IconArrowR />}
                        </div>
                    </div>
                </Between>
            ) : renderChild()}

        </Button>
    )
}

export const BtnNext = (props) => {
    return <Btn {...props} isNext />
}

export const BtnWhatsApp = (props) => {
    return <BtnNext {...props} type="whatsapp" i={IconWhatsapp} />
}

export const BtnPdf = (props) => {
    return <BtnNext {...props} type="invert" i={IconPdf} />
}

export const BtnEmail = (props) => {
    return <BtnNext {...props} type="invert" i={IconEmail} />
}

export const BtnCard = (props) => {
    return <Btn {...props} type="card" />
}

export const BtnLink = (props) => {
    return <Btn {...props} type="link" />
}

export const BtnSecondary = (props) => {
    return <Btn {...props} type="secondary" />
}

export const BtnInvert = (props) => {
    return <Btn {...props} type="invert" />
}

export const BtnLow = (props) => {
    return <Btn {...props} type="low" />
}

export const BtnBig = ({ title, info, i, theme }) => {
    return (
        <button className="BtnBig">
            <NoWrap>
                <IconRoundBig i={i} theme={theme} />
                <RowsStart>
                    <Label1>{title}</Label1>
                    <Label1 bold>{info}</Label1>
                </RowsStart>
            </NoWrap>
        </button>
    )
}

export const BtnRound = ({ i, onClick, disabled }) => {
    return (
        <button className="BtnRound" onClick={onClick} disabled={disabled}>
            {i()}
        </button>
    )
}

export const Touchable = ({ children, onClick }) => {
    return (
        <div className="Touchable" onClick={onClick}>
            {children}
        </div>
    )
}