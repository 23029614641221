import axios from 'axios'
import fileDownload from 'js-file-download'

export const downloadPdf = (code, name, setPer) => {
    return new Promise((res, rej) => {
        const url = process.env.REACT_APP_Z3_URL + `/pdf/${code}/${name}`;

        axios.get(url, {
            responseType: 'blob',
            onDownloadProgress: progressEvent => {
                const per = Math.ceil((progressEvent.progress * 100));
                setPer(per);
            }
        })
            .then((resp) => {
                fileDownload(resp.data, name + '.pdf');
                res();
            })
            .catch(rej)
            .finally(() => {
                setPer(0)
            })
    })
}