import { useLang } from "Lang/useLang";
import { Body2, Center, Content, Dropdown, Grid, Info, MarginTop, NoWrapTop, Overflow, RowsFull, Spread, SvgHighRiskAD, SvgHighRiskFA, SvgLowRiskAD, SvgLowRiskFA, TextInfo } from "UIKit"


export const Score = ({ score, field, riskList, risk, onRiskChange, isSuperEdit, data }) => {
    const { $ } = useLang();

    if (!score || !field) { return <></> }

    const section = scoreDetails[field];
    const sectionData = section[score];
    const infantFirstName = data.info.infantFirstName && data.info.infantAge !== 'no_born' ? `${data.info.infantFirstName}` : $('PLATFORM_INFO_BABY')

    return (
        <div media="risk-score">
            <Content>
                <Grid layout="score">
                    <Center>
                        <NoWrapTop>
                            {sectionData.icon}
                            <Info text={$(sectionData.info)} />
                        </NoWrapTop>
                    </Center>
                    <Overflow>
                        <RowsFull>
                            <TextInfo>{$('SCORE_INFO')}</TextInfo>

                            <Body2 bold>
                                {$(sectionData.summary, {
                                    b: (value) => <b className={score}>{value}</b>
                                }, infantFirstName)}
                            </Body2>
                            <Body2>
                                {$(sectionData.text)}
                            </Body2>
                        </RowsFull>
                    </Overflow>
                    {
                        isSuperEdit && (
                            <MarginTop>
                                <Spread>
                                    <Dropdown
                                        list={riskList}
                                        selected={risk}
                                        onChange={onRiskChange}
                                        isLang
                                    />
                                </Spread>
                            </MarginTop>
                        )
                    }
                </Grid>
            </Content>
        </div>
    )
}

export const scoreDetails = {
    "FA": {
        title: 'SCORE_FA_TITLE',
        "high": {
            icon: <SvgHighRiskFA />,
            summary: 'SCORE_FA_HIGH_SUMMARY',
            info: 'SCORE_FA_HIGH_INFO',
            text: 'SCORE_FA_HIGH',
            message: 'SCORE_FA_HIGH_MESSAGE'
        },
        "na": {
            icon: <SvgHighRiskFA />,
            summary: 'SCORE_FA_HIGH_SUMMARY',
            info: 'SCORE_FA_HIGH_INFO',
            text: 'SCORE_FA_HIGH',
            message: 'SCORE_FA_HIGH_MESSAGE'
        },
        "low": {
            icon: <SvgLowRiskFA />,
            summary: 'SCORE_FA_LOW_SUMMARY',
            info: 'SCORE_FA_LOW_INFO',
            text: 'SCORE_FA_LOW',
            message: 'SCORE_FA_LOW_MESSAGE'
        }
    },
    "AD": {
        title: 'SCORE_AD_TITLE',
        "high": {
            icon: <SvgHighRiskAD />,
            summary: 'SCORE_AD_HIGH_SUMMARY',
            info: 'SCORE_AD_HIGH_INFO',
            text: 'SCORE_AD_HIGH',
            message: 'SCORE_AD_HIGH_MESSAGE'
        },
        "na": {
            icon: <SvgHighRiskAD />,
            summary: 'SCORE_AD_HIGH_SUMMARY',
            info: 'SCORE_AD_HIGH_INFO',
            text: 'SCORE_AD_HIGH',
            message: 'SCORE_AD_HIGH_MESSAGE'
        },
        "low": {
            icon: <SvgLowRiskAD />,
            summary: 'SCORE_AD_LOW_SUMMARY',
            info: 'SCORE_AD_LOW_INFO',
            text: 'SCORE_AD_LOW',
            message: 'SCORE_AD_LOW_MESSAGE'
        }
    }
}