import { useLang } from "Lang/useLang";
import { Body2, Btn, BtnInvert, BtnLink, Center, CheckBox, Content, H3, InnerMed, InnerSmall, InnerThin, Padding, Rows, RowsFull, RowsMedium, Text } from "UIKit"
import { useState } from "react"
import { scoreDetails } from "./Score";
import { Link } from "react-router-dom";
import { routes } from "helpers/api";

export const OverrideScore = (props) => {
    const [isBooking, setIsBooking] = useState(true);
    const { $ } = useLang();

    const handleChange = (_, checked) => {
        setIsBooking(checked);
    }

    const handleSend = () => {
        props.onSend(isBooking);
    }


    const handleModal = () => {
        window.popup({ elem: <WarningModal {...props} onSend={handleSend} />, closeBtn: true });
    }

    return (
        <Content>
            <Rows>
                <CheckBox item={{
                    checked: isBooking,
                    title: 'OVERRIDE_SCORE_INVITE'
                }} onChange={handleChange} />
                <Btn isLoading={props.isLoading} onClick={handleModal} disabled={props.disabled}>{$('OVERRIDE_SEND_MAIL')}</Btn>
                <Text>{$('OVERRIDE_SCORE_INFO', {
                    link: (value) => <a rel="noreferrer" href={routes.landing + '/learn-more'} target="_blank">{value}</a>,
                    b: (value) => <b>{value}</b>
                })}</Text>
            </Rows>
        </Content>
    )
}

const WarningModal = ({ score, field, data, onSend, isLoading }) => {
    const { $ } = useLang();

    const section = scoreDetails[field];
    const info = section[score];
    const infantName = data.info.infantFirstName || $('PREFIX_THE_BABY');

    const handleClose = () => {
        window.popupHide();
    }

    return (
        <InnerSmall>
            <Padding>
                <RowsMedium>
                    <Center>
                        {info.icon}
                    </Center>
                    <Body2 bold>
                        {$(info.message, {
                            b: (value) => <b className={score}>{value}</b>
                        }, infantName, data.info.email)}
                    </Body2>
                    <InnerThin>
                        <Center>
                            <Rows>
                                <Btn isLoading={isLoading} onClick={onSend}>{$('OVERRIDE_POPUP_SEND')}</Btn>
                                <BtnInvert onClick={handleClose}>{$('OVERRIDE_POPUP_CLOSE')}</BtnInvert>
                            </Rows>
                        </Center>
                    </InnerThin>
                </RowsMedium>
            </Padding>
        </InnerSmall>
    )
}