
export const setCookie = (cname, cValue, exDays = 80) => {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cValue + ";" + expires + ";path=/; domain=." + extractRootDomain();
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const deleteCookie = (cname) => {
    setCookie(cname, '', -100);
}

function extractRootDomain() {
    var domain = window.location.hostname;
    var splitArr = domain.split('.'),
        arrLen = splitArr.length;

    // Extracting the root domain here
    // If there is a subdomain 
    if (arrLen > 2) {
        domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
        // Check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".co.uk")
        if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
            // This is a special case for ccTLDs
            domain = splitArr[arrLen - 3] + '.' + domain;
        }
    }
    return domain;
}