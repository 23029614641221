import { useLang } from "Lang/useLang"
import { InnerMed, H4, Padding, Between, SepLine, IconClose, BtnBig, IconEmailAt, GridEven, Content, Paragraph, RowsMedium, OpenMail } from "UIKit"
import { IconRound } from "UIKit/Elements/Icon/Icon"

export const DoctorNotes = () => {
    const { $ } = useLang();
    
    return (
        <div>
            <InnerMed>
                <Padding>
                    <Between>
                        <H4>{$('DOCTOR_NOTE_TITLE')}</H4>
                        <IconRound i={IconClose} onClick={window.popupHide} />
                    </Between>
                </Padding>
                <SepLine />
                <Padding>
                    <RowsMedium>
                        <GridEven>
                            {/* <BtnBig
                            title="Chat with us"
                            info="WhatsApp Us"
                            i={IconWhatsapp}
                            theme="whatsapp"
                        /> */}
                            <OpenMail to={$('PREFIX_SUPPORT_EMIL')}>
                                <BtnBig
                                    title={$('DOCTOR_NOTE_EMAIL_TITLE')}
                                    info={$('PREFIX_SUPPORT_EMIL')}
                                    i={IconEmailAt}
                                    theme="email"
                                />
                            </OpenMail>
                        </GridEven>
                        <Content
                            theme="blue"
                            title={$('FAQ_TITLE')}
                        >
                            <RowsMedium>
                                <Paragraph
                                    title={$('FAQ_1_TITLE')}
                                    info={$('FAQ_1_INFO')}
                                />
                                <Paragraph
                                    title={$('FAQ_2_TITLE')}
                                    info={$('FAQ_2_INFO')}
                                />
                                <Paragraph
                                    title={$('FAQ_3_TITLE')}
                                    info={$('FAQ_3_INFO')}
                                />
                            </RowsMedium>
                        </Content>
                    </RowsMedium>
                </Padding>
            </InnerMed>
        </div >
    )
}