import axios from "axios";
import { getUserToken } from "Context/storage";

axios.defaults.withCredentials = true

export const routes = {
  server: process.env.REACT_APP_SERVER_URL,
  upload: process.env.REACT_APP_Z3_ROUTES_UPLOADPDF,
  domains: process.env.REACT_APP_SERVER_ROUTES_DOMAINS,
  ml: process.env.REACT_APP_SERVER_ROUTES_ML,
  auth: process.env.REACT_APP_SERVER_ROUTES_AUTH,
  resultsAdmin: process.env.REACT_APP_SERVER_ROUTES_RESULTSADMIN,
  landing: process.env.REACT_APP_LANDING_PORT
};

class Api {
  constructor() { }

  send = async (
    method,
    url,
    data = null,
    config = this.getHeaders(),
    onCatch
  ) => {
    try {
      const resp = await axios[method](url, data || config, config);
      return resp.data;
    } catch (error) {
      if (onCatch) {
        throw error;
      }
      await this.handleError(error);
    }
  };

  getError = (error) => {
    if (error.response) {
      const err = error.response?.data?.error || { message: "Error" };

      switch (err.name) {
        case "JsonWebTokenError":
        case "TokenExpiredError":
          window.location.href = '/login';
          return `Token - ${err.message}`;
        default:
          return `${err.message || err}`;
      }
    } else if (error.message) {
      return `${error.message}`;
    } else if (error.request) {
      return error.request;
    } else {
      return "Error";
    }
  };

  getCode = (error) => {
    if (error.response) {
      const err = error.response?.data?.error || { message: "Error" };

      if(err.name) {
        return err.name;
      }
    } 
    return error.name || "Error";
  }

  handleError = async (error) => {
    const pop = async (msg) => {
      if (window.popup) {
        window.toastError(msg);
      }
    };

    const message = this.getError(error);
    await pop(message);
    throw error;
  };

  getHeaders = (contentType = "application/json") => {
    return {
      headers: {
        "Content-Type": contentType,
        Auth: getUserToken(),
      },
    };
  };

  get = async (route, params, ...args) => this.send("get", route, { params }, ...args);
  post = async (...args) => this.send("post", ...args);
  delete = async (...args) => this.send("delete", ...args);

  upload = async (formData) => {
    return await this.send(
      "post",
      routes.upload,
      formData,
      this.getHeaders("multipart/form-data")
    );
  };
}

export const api = new Api();
