
import { SepLine, Padding, SpreadEnd, IconArrowL, Label1, CenterAlign, Btn } from "UIKit"
import { Header } from "./Header";
import { useLang } from "Lang/useLang";

export const Sent = ({ onBack }) => {
    const { $ } = useLang();
    
    return (
        <>
            <Header />
            <Padding>
                <CenterAlign>
                    <Label1>
                        {$('CODE_SENT_MESSAGE')}
                    </Label1>
                </CenterAlign>
            </Padding>

            <SepLine />
            <Padding>
                <SpreadEnd>
                    <Btn onClick={onBack} type={'invert'} i={IconArrowL} >{$('CODE_SENT_BACK')}</Btn>
                </SpreadEnd>
            </Padding>
        </>
    )
}