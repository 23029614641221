import { IconFactory, IconClassified, RowsShort, IconMD, IconRef, SmallText, SelfEnd, IconECRep, IconUdi, Line } from 'UIKit';
import './Legals.css';
import { useLang } from 'Lang/useLang';
import { COUNTRIES, LANGS_KEYS } from 'types/countries';

export const Legals = () => {
    const { $, langKey } = useLang();
    
    if (langKey === LANGS_KEYS['en-au']) {
        return (
            <div className="Legals">
                <div className='top'>
                    <section>
                        <IconFactory />
                        <SmallText>{$('ABOUT_FACTORY', <a href='http://www.myorcare.com'>http://www.myorcare.com</a>)}</SmallText>
                    </section>
                    <section>
                        <RowsShort>
                            <Line>
                                <RowsShort>
                                    <IconMD />
                                    <IconRef />
                                </RowsShort>
                                <RowsShort>
                                    <IconClassified />
                                </RowsShort>
                            </Line>

                        </RowsShort>
                        <SelfEnd>
                            <SmallText>{$('ABOUT_CODE')}</SmallText>
                        </SelfEnd>
                    </section>
                </div>
            </div>
        )
    }

    if (langKey === LANGS_KEYS.en) {
        return (
            <div className="Legals">
                <div className='top'>
                    <section>
                        <IconFactory />
                        <SmallText>{$('ABOUT_FACTORY', <a href='http://www.myorcare.com'>http://www.myorcare.com</a>)}</SmallText>
                    </section>
                   
                </div>
            </div>
        )
    }

    return (
        <div className="Legals">
            <div className='top'>
                <section>
                    <IconFactory />
                    <SmallText>{$('ABOUT_FACTORY', <a href='http://www.myorcare.com'>http://www.myorcare.com</a>)}</SmallText>
                </section>
                <section>
                    <IconClassified />
                    <SmallText>{$('ABOUT_CLASSIFIED')}</SmallText>
                </section>
                <section>
                    <RowsShort>
                        <IconMD />
                        <IconRef />
                    </RowsShort>
                    <SelfEnd>
                        <SmallText>{$('ABOUT_CODE')}</SmallText>
                    </SelfEnd>
                </section>
            </div>
            <div className='bottom'>
                <section>
                    <IconECRep />
                    <SmallText>{$('ABOUT_EC_REP')}</SmallText>
                </section>
                <section>
                    <IconUdi />
                    <SmallText>{$('ABOUT_UDI')}</SmallText>
                </section>
            </div>
        </div>
    )

}