import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { createPortal } from "react-dom";
import { H3, BtnLink, Center, IconAccept, Label1, Label2, Line, Padding, Rows, RowsFull, SepLine, Btn, SpreadEven, MarginTop, MarginBig, PaddingTop, IconClose, Touchable } from "UIKit";

import './Modal.css';
import { IconRound } from "UIKit/Elements/Icon/Icon";
import { useLang } from "Lang/useLang";

export const modalContext = createContext({});

const Provider = modalContext.Provider;

export const ModalProvider = ({ children }) => {
    const [isShow, setIsShow] = useState(false);

    const value = {
        isShow,
        setIsShow
    }
    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}

export const useModal = () => {
    const { isShow, setIsShow } = useContext(modalContext);

    return {
        isShow,
        setIsShow
    }
}

export const Modal = () => {
    const [props, setProps] = useState({});
    const { isShow, setIsShow } = useModal();

    useEffect(() => {
        window.popup = (p) => {
            return new Promise((res) => {
                setIsShow(true);
                setProps({
                    ...p, onRes: (resp) => {
                        setIsShow(false);
                        res(resp)
                    }
                });
            })
        }

        window.conf = (msg, title) => window.popup({ msg, title, isConf: true })

        window.toast = (msg, type = "ok", time = 5000) => window.popup({ msg, type, time, toast: true });
        window.toastError = (msg, type = "error", time = 5000) => window.popup({ msg, type, time, toast: true });

        window.popupHide = () => setIsShow(false)
    }, [])

    if (!isShow) {
        return null
    }

    return createPortal(props.toast ? <Toast {...props} /> : <Popup {...props} />, document.getElementById('modal'));
}

export const Popup = (props) => {

    const wrapRef = useRef();
    useEffect(() => {
        setTimeout(() => {
            wrapRef?.current?.classList.add('shown');
        }, 100);
    }, [])

    return (
        <div ref={wrapRef} className="Popup" data-media="modal-popup">
            <div className="screen">
                <div className="content">
                    {props.closeBtn && (
                        <div className="close_btn">
                            <IconRound i={IconClose} onClick={() => window.popupHide()} />
                        </div>
                    )}
                    {props.msg ? <Message {...props} /> : props.elem}
                </div>
            </div>
        </div>
    )
}

export const Toast = ({ msg, type, time, onRes }) => {

    const wrapRef = useRef();
    useEffect(() => {
        setTimeout(() => {
            wrapRef?.current?.classList.add('shown');
        }, 200);

        setTimeout(() => {
            wrapRef?.current?.classList.remove('shown');
            setTimeout(() => {
                onRes(true);
            }, 500);
        }, time)
    }, [])


    return (
        <div ref={wrapRef} className="Toast" data-type={type} data-media="modal-toast">
            <div className="content">
                <Line>
                    <IconRound i={type === "ok" ? IconAccept : IconClose} />
                    {msg}
                </Line>
            </div>
        </div>
    )
}

const Message = ({ onRes, msg, isConf, title = "Message" }) => {
    const { $ } = useLang();

    return (
        <div className="msg" data-media="popup-msg">
            <PaddingTop>
                <Center>
                    <H3>{title}</H3>
                </Center>
            </PaddingTop>
            <Padding>
                <Center>
                    <Label1>{msg}</Label1>
                </Center>
            </Padding>
            <SepLine />
            <Padding>
                <SpreadEven>
                    {isConf && <Btn type="invert" onClick={() => onRes(false)}>{$('MODAL_NO')}</Btn>}
                    <Btn onClick={() => onRes(true)}>{isConf ? $('MODAL_YES') : $('MODAL_OK')}</Btn>
                </SpreadEven>
            </Padding>
        </div>
    )
}
