import { userContext } from "Context/userContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

export const Home = () => {
    const { isSuperDietitian, data, isLoading } = useContext(userContext);

    if(isLoading) {
        return <></>
    }

    if(isSuperDietitian) {
        return <Navigate to='/dashboard' />
    }
    
    return <Navigate to='/platform' />
}