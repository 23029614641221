import React from "react";
import './Typography.css';

export const Typography = ({
    tag = 'h1',
    children = 'N/A',
    onClick,
    className,
    type,
    disabled,
    media,
    ...props
}) => {

    const attr = { onClick, className, disabled };
    
    //set weights
    const weights = ['light', 'regular', 'medium', 'bold'];
    attr[`typo-weight`] = Object.keys(props).find(i => weights.includes(i));

    //set size
    const types = ['body1', 'body2', 'subtitle1', 'subtitle2', 'label1', 'label2', 'info'];
    attr[`typo-types`] = Object.keys(props).find(i => types.includes(i));

    //set size
    const align = ['left', 'center', 'right'];
    attr[`typo-align`] = Object.keys(props).find(i => align.includes(i));

    attr['data-type'] = type;
    attr['data-media'] = media;

    return React.createElement(tag, attr, children)
}

export const H1 = (props) => <Typography tag="h1" {...props} />
export const H2 = (props) => <Typography tag="h2" {...props} />
export const H3 = (props) => <Typography tag="h3" {...props} />
export const H4 = (props) => <Typography tag="h4" {...props} />
export const SmallText = (props) => <Typography {...props} tag="label" />
export const Body1 = (props) => <Typography tag="p" body1 {...props} />
export const Body2 = (props) => <Typography tag="p" body2 {...props} />
export const TextInfo = (props) => <Typography tag="p" info {...props} />
export const Subtitle1 = (props) => <Typography tag="p" subtitle1 {...props} />
export const Subtitle2 = (props) => <Typography tag="p" subtitle2 {...props} />

export const Label1 = (props) => <Typography tag="label" label1 {...props} />
export const Label2 = (props) => <Typography tag="label" label2 {...props} />

export const Button = (props) => <Typography tag="button" {...props} />
export const Text = (props) => <Body1 {...props} />