import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { Input, BtnNext, SepLine, Padding, SpreadEnd, IconArrowL, Btn, Rows, Label1, CenterAlign } from "UIKit"
import { Header } from "./Header";
import { api, routes } from "helpers/api";
import { useState } from "react";
import { useLang } from "Lang/useLang";

export const Regenerate = ({ onBack, onSent }) => {
    const { $ } = useLang();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const LastName = useInput('', $('PATIENT_GENERATE_NAME_PH'), 'empty');
    const Email = useInput('', $('PATIENT_GENERATE_EMAIL_PH'), 'empty, email');

    const validate = useValidator(LastName, Email);

    if (isError) {
        Email.validation.error = $('PATIENT_GENERATE_ERROR');
    }

    const handleCode = () => {
        setIsError(false);
        
        if (!validate()) { return }
        
        setIsLoading(true);

        api.post(routes.ml + '/regenerate-code', {
            lastName: LastName.value,
            email: Email.value
        }, api.getHeaders(), true)
            .then(() => {
                onSent();
            })
            .catch(() => {
                setIsError(true);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <Header />
            <Padding>
                <Rows>
                    <Padding>
                        <CenterAlign>
                            <Label1>
                                {$('PATIENT_GENERATE_TITLE')}
                            </Label1>
                        </CenterAlign>
                    </Padding>
                    <Input focus {...LastName} title={$('PATIENT_GENERATE_NAME_TITLE')} />
                    <Input {...Email} title={$('PATIENT_GENERATE_EMAIL_TITLE')} />
                </Rows>
            </Padding>

            <SepLine />
            <Padding>
                <SpreadEnd>
                    <Btn onClick={onBack} type={'invert'} i={IconArrowL} />
                    <BtnNext onClick={handleCode} isLoading={isLoading}>{$('PATIENT_GENERATE_BTN_CONTINUE')}</BtnNext>
                </SpreadEnd>
            </Padding>
        </>
    )
}