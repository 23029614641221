import { userContext } from "Context/userContext";
import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { useLang } from "Lang/useLang";
import { BtnNext, Input, Middle, RoundBox, RowsMedium, RowsShort } from "UIKit"
import { LoginWrapper } from "Wrapper/LoginWrapper";
import { api, routes } from "helpers/api";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"

export const LoginView = () => {
    const { $ } = useLang();
    const [isLoading, setIsLoading] = useState();
    const { login, user, logout } = useContext(userContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            logout();
        }
    }, [])

    const Email = useInput('', $('PAGE_LOGIN_FIELD_EMAIL_PH'), 'empty, email');
    const Password = useInput('', $('PAGE_LOGIN_FIELD_PASSWORD_PH'), 'empty');

    const validate = useValidator(Email, Password);


    const handleLogin = async () => {
        if (!validate()) { return }
        setIsLoading(true);

        try {
            await api.post(routes.resultsAdmin + '/login', {
                email: Email.value,
                password: Password.value
            }, null, true).finally(() => {
                setIsLoading(false);
            })

            login();
        } catch (error) {
            handleError(error);
        }


    }

    const handleError = async (error) => {
        const message = api.getError(error);
        if (api.getCode(error) === 'PasswordExpire') {
            await window.popup({ msg: $('ERROR_PASSWORD_EXPIRED') });
            navigate('change-password');

        } else {
            window.toastError(message);
        }
    }

    const isDisabled = !Email.value || !Password.value;

    return (
        <LoginWrapper
            title={$('PAGE_LOGIN_HEAD')}
            info={$('PAGE_LOGIN_TITLE')}
        >
            <RoundBox>
                <Middle>
                    <RowsMedium>
                        <RowsShort>
                            <Input focus title={$('PAGE_LOGIN_FIELD_EMAIL')} {...Email} type="email" />
                        </RowsShort>
                        <RowsShort>
                            <Input title={$('PAGE_LOGIN_FIELD_PASSWORD')} {...Password} type="password" />
                        </RowsShort>
                        <BtnNext disabled={isDisabled} isLoading={isLoading} onClick={handleLogin}>{$('PAGE_LOGIN_BTN_CONTINUE')}</BtnNext>
                        <Link to="forgot-password">{$('PAGE_LOGIN_FIELD_FORGOT')}</Link>
                    </RowsMedium>
                </Middle>
            </RoundBox>
        </LoginWrapper>
    )
}