import { setForgotDetails, setForgotEmail } from "Context/forgotPassword";
import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { useLang } from "Lang/useLang";
import { Btn, BtnNext, IconArrowL, Input, Middle, RoundBox, RowsMedium, RowsShort, SpreadEnd } from "UIKit"
import { LoginWrapper } from "Wrapper/LoginWrapper";
import { api, routes } from "helpers/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom"

export const ForgotPassword = () => {
    const { $ } = useLang();
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();
    const Email = useInput('', $('FORGOT_PASS_EMAIL_PLACEHOLDER'), 'empty, email');

    const validate = useValidator(Email);


    const handleReset = async () => {
        if (!validate()) { return }
        setIsLoading(true);

        try {
            await api.post(routes.resultsAdmin + '/forget-password', {
                email: Email.value
            })

            setForgotEmail(Email.value)

            navigate(`/login/email-callback`);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    const handleBack = () => {
        navigate('/login');
    }
    const isDisabled = !Email.value;

    return (
        <LoginWrapper
            title={$('FORGOT_PASS_TITLE')}
            info={$('FORGOT_PASS_INFO')}
        >
            <RoundBox decor={false}>
                <Middle>
                    <RowsMedium>
                        <RowsShort>
                            <Input focus title={$('FORGOT_PASS_EMAIL_TITLE')} {...Email} type="email"/>
                        </RowsShort>

                        <SpreadEnd>
                            <Btn onClick={handleBack} type={'invert'} i={IconArrowL} />
                            <BtnNext disabled={isDisabled} isLoading={isLoading} onClick={handleReset}>{$('BTN_CONTINUE')}</BtnNext>
                        </SpreadEnd>
                    </RowsMedium>
                </Middle>
            </RoundBox>
        </LoginWrapper>
    )
}