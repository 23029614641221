import { AuthRoute, AuthSuperDietitian, userContext } from "Context/userContext"
import { Idle } from "UIKit/Elements/Idle/Idle"
import { ChangePassword } from "Views/ChangePassword"
import { Dashboard } from "Views/Dashboard"
import { EmailCallback } from "Views/EmailCallback"
import { ForgotPassword } from "Views/ForgotPassword"
import { Home } from "Views/Home"
import { LoginView } from "Views/LoginView"
import { NewPassword } from "Views/NewPassword"
import { PlatformView } from "Views/PlatformView"
import { WelcomePassword } from "Views/WelcomePassword"
import { useContext } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"

export const Routes = ({ children }) => {
    const router = createBrowserRouter([
        {
            path: '/login',
            element: <LoginView />
        },
        {
            path: '/login/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/login/change-password',
            element: <ChangePassword />
        },
        {
            path: '/login/email-callback',
            element: <EmailCallback />
        },
        {
            path: '/login/new-password',
            element: <NewPassword />
        },
        {
            path: '/login/welcome/:email/:code',
            element: <WelcomePassword />
        },
        {
            path: '/platform/:code',
            element: <AuthRoute elem={PlatformView} />
        },
        {
            path: '/platform',
            element: <AuthRoute elem={PlatformView} />
        },
        {
            path: '/dashboard',
            element: <AuthSuperDietitian elem={Dashboard} />
        },
        {
            path: '/',
            element: <AuthRoute elem={Home} />
        },
    ])

    return (
        <RouterProvider router={router}>
            {children}
        </RouterProvider>
    )
}