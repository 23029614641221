import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Media.css';

import { App } from './App';
import { ModeProvider } from 'Context/modeContext';
import { Routes } from './Routes';
import { ModalProvider } from 'Shared/Modal/Modal';
import { UserProvider } from 'Context/userContext';
import { LangProvider } from 'Lang/useLang';

if(window.location.href.includes('//www.')) {
  window.location.href = window.location.href.replace('www.', '');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ModalProvider>
    <LangProvider>
      <UserProvider>
        <Routes>
          <ModeProvider>
            <App />
          </ModeProvider>
        </Routes>
      </UserProvider>
    </LangProvider>
  </ModalProvider>
);

