import { useLang } from "Lang/useLang";
import { useState } from "react";

export const useValidator = (...inputs) => {
    const [isActive, setIsActive] = useState(false);
    const { $ } = useLang();

    const validate = () => {
        inputs.forEach(input => {
            setValidation(input, $);
        });
    }

    if (isActive) {
        validate();
    }

    const isValid = () => {
        setIsActive(true);
        validate();
        return !inputs.find(i => i.validation.error);
    }

    return isValid;
}

const setValidation = (input, $) => {

    const { prefix } = input.validation;
    const list = prefix.split(',');

    for (let i = 0; i < list.length; i++) {
        const p = list[i].trim();
        const error = check(p, getVal(input), $);
        if (error) {
            input.validation.error = error;
            break
        }
    }
}

const getVal = (input) => {
    switch(input.validation.type) {
        case "checkbox": return input.checked;
        case "radio": return input.selected;
        case "file": return input?.data;
        default: return input.value;
    }
}

const check = (prefix, value, $) => {
    switch (prefix) {
        case "empty": {
            if (!value && typeof(value) !== 'boolean') {
                return $('VALIDATE_EMPTY');
            }
            break;
        }
        case "email": {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!value.match(validRegex)) {
                return $('VALIDATE_EMAIL');
            }
            break;
        }
        case "phone": {
            var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (!value.match(phoneno)) {
                return $('VALIDATE_PHONE');
            }
            break;
        }
        case "false": {
            if (!value) {
                return $('VALIDATE_FALSE');
            }
            break;
        }
        case "file": {
            if (!value?.file && !value?.url) {
                return $('VALIDATE_FILE_MISSING');
            }
            break;
        }
        case "password": {
            const list = [
                {
                    msg: $('VALIDATE_PASS_MIN'),
                    isError: value.length < 8
                },
                {
                    msg: $('VALIDATE_PASS_UPPER'),
                    isError: !value.match(/[A-Z]/)
                },
                {
                    msg: $('VALIDATE_PASS_LOWER'),
                    isError: !value.match(/[a-z]/)
                },
                {
                    msg: $('VALIDATE_PASS_NUM'),
                    isError: !value.match(/[0-9]/)
                },
                {
                    msg: $('VALIDATE_PASS_SPECIAL'),
                    isError: !value.match(/[`§!%$&@#\^\[\[\{\}\"_\/\\\:\,\+\-\=`<>^*\(\)\[\]]+/)
                }
            ]
            const err = list.find(i => i.isError);
            if (err) {
                return {
                    msg: $('VALIDATE_PASS_ERR', err.msg),
                    list
                }
            }
            break;
        }
    }
}