
export const convertWeight = (value, unit) => {
    if (unit === "lb") {
        // Convert pounds to kilograms (1 pound = 0.45359237 kilograms)
        return +value * 0.45359237;
    } else if (unit === "kg") {
        // Convert kilograms to pounds (1 kilogram = 2.20462262 pounds)
        return +value * 2.20462262;
    } else {
        return +value;
    }
}

export const convertPoundToKg = (value) => {
    return parseInt(convertWeight(value, 'lb'))
}

export const convertKgToPounds = (value) => {
    return parseInt(convertWeight(value, 'kg'))
}

export const getDateFormat = (date, format = '') => {
    const d = new Date(date);

    const dd = d.getDate();
    const mm = d.getMonth() + 1;

    const day = dd < 10 ? "0" + dd : dd;
    const month = mm < 10 ? "0" + mm : mm;
    const year = d.getFullYear();

    if (format === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`
    }
    return `${month}/${day}/${year}`
}
