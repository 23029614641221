import { getForgotDetails } from "Context/forgotPassword";
import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { useLang } from "Lang/useLang";
import { BtnNext, Input, Label2, Middle, RoundBox, RowsMedium, RowsShort } from "UIKit"
import { LoginWrapper } from "Wrapper/LoginWrapper";
import { api, routes } from "helpers/api";
import { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom"

export const NewPassword = () => {
    const { $ } = useLang();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const navigate = useNavigate();
    const Password = useInput('', '', 'password');

    const validate = useValidator(Password);
    const { email, code } = getForgotDetails();

    if (error) {
        Password.validation.error = error;
    }

    const handleReset = () => {
        if (!validate()) { return }


        setIsLoading(true);


        api.post(routes.resultsAdmin + '/set-password', {
            email, code, password: Password.value
        }, api.getHeaders(), true)
            .then(resp => {
                setTimeout(handleNavigate, 5000);
                setIsDone(true);
            })
            .catch(e => {
                const message = api.getError(e);
                window.toastError(message);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNavigate = () => {
        navigate('/login');
    }

    const isDisabled = !Password.value;

    if (!email || !code) {
        return <Navigate to='/login' />
    }

    return (
        <LoginWrapper
            title={$('NEWPASS_TITLE')}
            info={$('NEWPASS_INFO')}
        >
            <RoundBox decor={false}>
                <Middle>
                    {isDone ? (
                        <RowsMedium>
                            <Label2>
                                {$('WELCOME_REDIRECTED')}
                            </Label2>

                            <BtnNext onClick={handleNavigate}>{$('WELCOME_BTN_SIGNIN')}</BtnNext>
                        </RowsMedium>

                    ) : (
                        <RowsMedium>
                            <RowsShort>
                                <Input focus title={$('PAGE_LOGIN_FIELD_PASSWORD')} type="password" {...Password} />
                            </RowsShort>

                            <BtnNext disabled={isDisabled} isLoading={isLoading} onClick={handleReset}>{$('WELCOME_BTN_CONTINUE')}</BtnNext>
                        </RowsMedium>

                    )}
                </Middle>
            </RoundBox>
        </LoginWrapper>

    )
}