import { useInput } from "Hooks/useInput";
import { useValidator } from "Hooks/useValidator";
import { RowsShort, Subtitle1, Input, BtnNext, SepLine, Padding, Between, BtnLink, Btn, IconArrowL, SpreadEnd } from "UIKit"
import { useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { useLang } from "Lang/useLang";
import { useEffect, useState } from "react";
import { api } from "helpers/api";

export const EnterCode = ({ onRegenerate, isBack }) => {
    const { $ } = useLang();
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const Code = useInput('', $('PATIENT_ENTER_PH'), 'empty');

    const validate = useValidator(Code);

    useEffect(() => {
        setError('');
    }, [Code.value])

    if (error) {
        Code.validation.error = error;
    }

    const handleCode = async () => {
        if (!validate()) { return }

        const resp = await api.get(process.env.REACT_APP_SERVER_ROUTES_ML + '/' + Code.value);

        if (!resp) {
            setError($('CODE_ENTER_ERROR'));
            return;
        }

        navigate('/platform/' + Code.value);
    }

    const handleBack = () => {
        navigate(isBack ? -1 : '/login');
    }

    return (
        <>
            <Header />
            <Padding>
                <RowsShort>
                    <Between>
                        <Subtitle1 bold left>{$('PATIENT_ENTER_TITLE')}</Subtitle1>
                        <BtnLink onClick={onRegenerate}>{$('PATIENT_BTN_GEN')}</BtnLink>
                    </Between>

                    <Input focus {...Code} />
                </RowsShort>
            </Padding>

            <SepLine />
            <Padding>
                <SpreadEnd>
                    <Btn onClick={handleBack} type={'invert'} i={IconArrowL} />
                    <BtnNext onClick={handleCode}>{$('PATIENT_BTN_CONTINUE')}</BtnNext>
                </SpreadEnd>
            </Padding>
        </>
    )
}