import './Line.css';

export const Line = ({ children, addClass }) => {
    return (
        <div className={`Line ${addClass || ''}`}>
            {children}
        </div>
    )
}

export const Rows = (props) => {
    return <Line {...props} addClass="rows" />
}

export const RowsStart = (props) => {
    return <Line {...props} addClass="rows start" />
}

export const RowsShort = (props) => {
    return <Line {...props} addClass="rows short" />
}

export const RowsMedium = (props) => {
    return <Line {...props} addClass="rows medium" />
}

export const RowsFull = (props) => {
    return <Line {...props} addClass="rows full" />
}

export const Between = (props) => {
    return <Line {...props} addClass="between" />
}

export const End = (props) => {
    return <Line {...props} addClass="end" />
}

export const Center = (props) => {
    return <Line {...props} addClass="center" />
}

export const Middle = (props) => {
    return <Line {...props} addClass="middle" />
}

export const CenterAlign = (props) => {
    return <Line {...props} addClass="center-align" />
}

export const StartAlign = (props) => {
    return <Line {...props} addClass="start-align" />
}

export const Spread = (props) => {
    return <Line {...props} addClass="spread" />
}

export const SpreadEven = (props) => {
    return <Line {...props} addClass="spread-even" />
}

export const SpreadEvenBase = (props) => {
    return <Line {...props} addClass="spread-even-base" />
}

export const SpreadEnd = (props) => {
    return <Line {...props} addClass="spread-end" />
}

export const Short = (props) => {
    return <Line {...props} addClass="short" />
}

export const SelfStart = (props) => {
    return <Line {...props} addClass="self-start" />
}

export const NoWrap = (props) => {
    return <Line {...props} addClass="no-wrap" />
}

export const NoWrapTop = (props) => {
    return <Line {...props} addClass="no-wrap-top" />
}

export const RowsFullHigh = (props) => {
    return <Line {...props} addClass="rows full_high" />
}

export const SelfEnd = (props) => {
    return <Line {...props} addClass="self-end" />
}
