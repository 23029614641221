import { useLang } from "Lang/useLang";
import { BtnRound, Text, IconArrowEnd, IconArrowNext, IconArrowPrev, IconArrowStart, InputRound, Line } from "UIKit";

export const Paging = ({ state, total, totalPages }) => {
    const { $ } = useLang();
    const { value, onChange } = state;

    return (
        <Line>
            <Text>{$('PAGING_TOTAL', total)}</Text>
            <BtnRound i={IconArrowStart} onClick={() => onChange(1)} disabled={+value === 1} />
            <BtnRound i={IconArrowPrev} onClick={() => onChange(+value - 1)} disabled={+value === 1} />
            <InputRound {...state} numeric min={1} max={totalPages} />
            <Text bold>{$('PAGING_OF', totalPages)}</Text>
            <BtnRound i={IconArrowNext} onClick={() => onChange(+value + 1)} disabled={+value === totalPages} />
            <BtnRound i={IconArrowEnd} onClick={() => onChange(totalPages)} disabled={+value === totalPages} />
        </Line>
    )
}