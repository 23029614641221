export const COUNTRIES = {
    US: '6574e9434b8bc4c10fc80743',
    AUSTRALIA: '6574e9a34b8bc4c10fc80745',
    SPAIN: '6574e9db4b8bc4c10fc80746',
    MEXICO: '6574e9884b8bc4c10fc80744'
}

export const LANGS_KEYS = {
    "en": "en",
    "sp": "sp",
    "sp-mx": "sp-mx",
    "en-irl": "en-irl",
    "en-au": "en-au"
}