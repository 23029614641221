import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getStoredLang, setStoredLang } from "./storage";
import { api, routes } from "helpers/api";
import { IconIRLFlag, IconSPFlag, IconSPmxFlag, IconUsFlag, IconAUFlag } from "UIKit";
import { useUser } from "Context/userContext";

const flags = {
  "en": <IconUsFlag />,
  "sp": <IconSPFlag />,
  "sp-mx": <IconSPmxFlag />,
  "en-irl": <IconIRLFlag />,
  "en-au": <IconAUFlag />
}


export const langContext = createContext({});

const Provider = langContext.Provider;

export const useLang = () => {
  const data = useContext(langContext);
  return { ...data };
};

export const LangProvider = ({ children }) => {
  const { isUser } = useUser();
  const [isOn, setIsOn] = useState(false);
  const [keys, setKeys] = useState();
  const [langs, setLangs] = useState([]);

  const [lang, setLang] = useState(getStoredLang());
  const keysRef = useRef();
  keysRef.current = keys;
  const langIndex = langs.length && isNaN(lang) ? langs.find(i => i._id === lang).index : lang;

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const keysList = await api.get(routes.server + "/translation/results");
    const langsList = await api.get(routes.server + "/translation/langs");

    setKeys(keysList);
    setLangs(langsList);
    setIsOn(true);
  }

  const getLangId = () => {
    if(!isNaN(lang) && langs.length) {
      return langs[langIndex || 0]._id;
    }
    return lang;
  }

  const value = {
    lang,
    langKey: langs.find(l => l.index === +lang)?.key,
    isOn,
    langIndex,
    setLang: (key) => {
      setStoredLang(key);
      setLang(key);
      if(isUser) {
        api.post(routes.resultsAdmin + '/change-lang', { lang: key})
      }
    },
    $: (params, ...args) => {
      const keys = keysRef.current;
      
      if (!keys) {
        return "";
      }
      if (!params) {
        return "";
      }
      const [key, extra] = params.split(":");

      if (extra) {
        args.push(extra);
      }

      let tagsObj = {};
      if (typeof (args[0]) === 'object' && !args[0].$$typeof) {
        tagsObj = args[0];
        args.splice(0, 1);
      }

      let value = (keys[key] && keys[key][langIndex]) || `*${key}*`;

      const mapped = value
        .replace(/({arg([0-9]+)})/gi, '||$1||')
        .replace(/(<[a-z]+>([^<]+)<[a-z]+>)/gi, '||$1||')
        .split('||').reduce(
          (state, val) => {
            const arg = val.match(/{arg([0-9]+)}/);
            const tag = val.match(/<([a-z]+)>([^<]+)<[a-z]+>/);

            if (tag && tag.length > 2) {
              const [_, field, value] = tag;
              const cond = tagsObj[field];
              if (cond) {
                state.push(cond(value));
              }
            } else if (arg) {
              state.push(args[+arg[1] - 1]);
            } else {
              state.push(val);
            }
            return state;
          },
          []
        );

      if (mapped.length === 1) {
        return mapped[0];
      }

      return mapped.map((m, i) => <Fragment key={i}>{m}</Fragment>);
    },
    getLangs: () => langs.map(i => (
      {
        id: i._id,
        value: i.national,
        icon: flags[i.key]
      }
    )),
    dd: () => (
      {
        selected: getLangId(),
        onChange: value.setLang,
        list: value.getLangs()
      }
    ),
  };

  return <Provider value={value}>{children}</Provider>;
};
